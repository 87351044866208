<template>
  <div class="layoutContent">
    <div v-html="description" class="contentParty" v-show="description != ''" />
    <k-alert-msg ref="lblMsg" />
    <KValidationObserver ref="observer">
      <k-form
        @keydown.enter="isPrevent"
        @submit.prevent="saveForm"
        class="contentParty"
      >
        <div class="cardBody" style="display: flex; flex-direction: column">
          <k-form-group
            label-suffix="election"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: 0,
            }"
          >
            <k-form-input
              v-model="$route.query.electionTitle"
              :plaintext="true"
            />
          </k-form-group>
          <k-form-group
            label-suffix="electionDate"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: 0,
            }"
          >
            <k-form-input
              v-model="$route.query.electionDay"
              :plaintext="true"
            />
          </k-form-group>
          <div
            class="cardHead"
            :style="{
              order: 0,
            }"
          >
            {{ $g("personalData") }}
          </div>
          <k-form-group
            id="photoImg"
            label-suffix="photo"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.photo),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.photo) ? 'require-mark' : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.photo)"
          >
            <div class="d-flex flex-wrap justify-content-between">
              <div class="mr-2">
                <k-img :src="form.photoPath" style="max-height: 100px" />
              </div>
              <div class="d-flex flex-wrap align-items-start mt-2">
                <k-button
                  @click="changePhoto"
                  class="mb-1 mr-2"
                  v-if="!isFieldViewOnly(workerFieldNameEnums.photo)"
                >
                  buttons.upload
                </k-button>
                <k-button
                  @click="removePhoto"
                  variant="utility"
                  class="mb-1"
                  v-if="!isFieldViewOnly(workerFieldNameEnums.photo)"
                >
                  buttons.remove
                </k-button>
              </div>
            </div>
          </k-form-group>
          <k-form-group
            id="voterId"
            label-suffix="voterId"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            class="voterIdGroup"
            :class="{
              voterIdGroupWithValue:
                form.voterCode != null && form.voterCode.length > 0,
            }"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.electorId),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.electorId)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.electorId)"
          >
            <k-row>
              <k-col cols="5">
                <div
                  class="col-form-label"
                  v-if="form.voterCode != null && form.voterCode.length > 0"
                >
                  {{ form.voterCode }}
                </div>
              </k-col>
              <k-col class="d-flex justify-content-end voterIdBtn" cols="7">
                <k-button @click="OnVoterAuthenticationClick">
                  buttons.voterAuthentication
                </k-button>
                <k-button
                  v-show="form.voterCode != null && form.voterCode.length > 0"
                  class="ml-2"
                  @click="OnVoterAuthenticationClear"
                >
                  buttons.clear
                </k-button>
              </k-col>
            </k-row>
          </k-form-group>

          <k-form-group
            id="firstNameInput"
            label-suffix="firstName"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.firstName),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.firstName)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.firstName)"
          >
            <KValidationProvider
              name="firstName"
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.firstName),
                max: 200,
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="firstName"
                v-model="form.firstName"
                :aria-label="$g('firstName')"
                :plaintext="
                  nonEditableFields[nonEditableFieldsKey.FirstName] ||
                  isFieldViewOnly(workerFieldNameEnums.firstName)
                "
                :tab-index="getOrderIndex(workerFieldNameEnums.firstName)"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="middleNameInput"
            label-suffix="middleName"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.middleName),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.middleName)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.middleName)"
          >
            <KValidationProvider
              name="middleName"
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.middleName),
                max: 200,
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="middleName"
                v-model="form.middleName"
                :aria-label="$g('middleName')"
                :plaintext="
                  nonEditableFields[nonEditableFieldsKey.MiddleName] ||
                  isFieldViewOnly(workerFieldNameEnums.middleName)
                "
                :tab-index="getOrderIndex(workerFieldNameEnums.middleName)"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="lastNameInput"
            label-suffix="lastName"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.lastName),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.lastName)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.lastName)"
          >
            <KValidationProvider
              name="lastName"
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.lastName),
                max: 200,
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="lastName"
                v-model="form.lastName"
                :aria-label="$g('lastName')"
                :plaintext="
                  nonEditableFields[nonEditableFieldsKey.LastName] ||
                  isFieldViewOnly(workerFieldNameEnums.lastName)
                "
                :tab-index="getOrderIndex(workerFieldNameEnums.lastName)"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="suffixInput"
            label-suffix="Suffix"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.suffix),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.suffix) ? 'require-mark' : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.suffix)"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.suffix),
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-select
                v-model="form.suffix"
                :options="suffixOptions"
                :plaintext="isFieldViewOnly(workerFieldNameEnums.suffix)"
                :tab-index="getOrderIndex(workerFieldNameEnums.suffix)"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="dobDatepicker"
            label-suffix="dob"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.dob),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.dob) ? 'require-mark' : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.dob)"
          >
            <KValidationProvider
              name="dob"
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.dob),
                validateDateFormat: true,
                validateDateIsRequired: isFieldRequired(
                  workerFieldNameEnums.dob
                ),
                remote: checkAge,
                dob: true,
              }"
              v-slot="{ errors }"
              slim
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-datepicker
                v-model="form.dob"
                :tab-index="getOrderIndex(workerFieldNameEnums.dob)"
                :plaintext="isFieldViewOnly(workerFieldNameEnums.dob)"
                @change="onDobChange"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="yobDatepicker"
            label-suffix="yearOfBirth"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.yob),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.yob) ? 'require-mark' : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.yob)"
          >
            <KValidationProvider
              name="yob"
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.yob),
                validateDateFormat: true,
                validateDateIsRequired: isFieldRequired(
                  workerFieldNameEnums.yob
                ),
                remote: checkAge,
                dob: true,
              }"
              v-slot="{ errors }"
              slim
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-select
                v-model="form.yob"
                :options="dobOptions"
                :plaintext="isFieldViewOnly(workerFieldNameEnums.yob)"
                :tab-index="getOrderIndex(workerFieldNameEnums.yob)"
                @change="onYobChange"
              />
            </KValidationProvider>
          </k-form-group>
          <!-- <k-form-group
            label-suffix="itemTag"
            id="input-group-tag"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              rules="max:200"
              v-slot="{ errors }"
              slim
              name="Item Tag"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-multiselect
                id="-tag"
                ref="multiselect"
                :options="tagList"
                :taggable="true"
                :addTag="addTag"
                v-model="form.selectedTagList"
              />
            </KValidationProvider>
          </k-form-group> -->
          <k-form-group
            :style="{
              order: getOrderIndex(workerFieldNameEnums.electionInspector),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.electionInspector)
                ? 'require-mark'
                : ''
            "
            v-if="
              showElectionInspector &&
              isFieldShow(workerFieldNameEnums.electionInspector)
            "
            label-suffix="electionInspector"
            label-for="electionInspector"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(
                  workerFieldNameEnums.electionInspector
                ),
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-select
                v-model="form.electionInspector"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.electionInspector)
                "
                :options="yesAndNoOptions"
                :tab-index="
                  getOrderIndex(workerFieldNameEnums.electionInspector)
                "
                @change="onChangeElectionInspector"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="partySelect"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            label-suffix="party"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.partyId),
            }"
            :label-class="
              politicalPartyRequired || form.electionInspector == 2
                ? 'require-mark'
                : ''
            "
            v-if="isShowPartyId"
          >
            <KValidationProvider
              :rules="{
                required: politicalPartyRequired || form.electionInspector == 2,
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-select
                v-model="form.partyId"
                :options="partys"
                :plaintext="
                  nonEditableFields[nonEditableFieldsKey.PartyId] ||
                  isFieldViewOnly(workerFieldNameEnums.partyId)
                "
                :tab-index="getOrderIndex(workerFieldNameEnums.partyId)"
              />
            </KValidationProvider>
          </k-form-group>
          <b-form-group
            label-suffix="sSN"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            id="ssnTooltip"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.ssn),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.ssn) ? 'require-mark' : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.ssn)"
          >
            <template #label>
              <span
                style="margin-right: 6px"
                :class="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'ssnTooltip',
                    'labelClass'
                  )
                "
                >{{ $g("sSN") }}:
              </span>
              <b-icon
                v-if="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'ssnTooltipIcon',
                    'vIf'
                  )
                "
                icon="info-circle-fill"
                v-b-tooltip="{
                  placement: 'righttop',
                  title: $g('SSNDescription'),
                  boundary: 'window',
                  container: 'ssnTooltip',
                }"
              />
            </template>
            <KValidationProvider
              :rules="
                ssnDigits == 9
                  ? {
                      required:
                        isFieldRequired(workerFieldNameEnums.ssn) &&
                        !isShowPassword
                          ? true
                          : false,
                      ssnLength: 9,
                      ssn: {
                        firstBanNumber: ['000', '666'],
                        firstBanNumberBetween: [900, 999],
                        middleNumberBetween: [0, 100],
                        lastnNumberBetween: [0, 10000],
                      },
                    }
                  : {
                      required:
                        isFieldRequired(workerFieldNameEnums.ssn) &&
                        !isShowPassword
                          ? true
                          : false,
                      ssnLength_4: {
                        numberBetween: [0, 10000],
                      },
                    }
              "
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <KSSN
                v-model="form.ssn"
                :ssn-digits="ssnDigits"
                :has-password-change-btn="true"
                :props-show-password="true"
                :tab-index="getOrderIndex(workerFieldNameEnums.ssn)"
                :plaintext="isFieldViewOnly(workerFieldNameEnums.ssn)"
              />
            </KValidationProvider>
          </b-form-group>
          <k-form-group
            id="raceSelect"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            label-suffix="whatsYourRace"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.raceId),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.raceId) ? 'require-mark' : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.raceId)"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.raceId),
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-select
                v-model="form.raceId"
                :options="raceOptions"
                :plaintext="isFieldViewOnly(workerFieldNameEnums.raceId)"
                :tab-index="getOrderIndex(workerFieldNameEnums.raceId)"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-suffix="workerType"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.workerTypeId),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.workerTypeId)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.workerTypeId)"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.workerTypeId),
              }"
              v-slot="{ errors }"
              slim
              name="workerType"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-select
                v-model="form.workerTypeId"
                :options="workerTypeList"
                @change="workerTypeIdChange"
                :plaintext="isFieldViewOnly(workerFieldNameEnums.workerTypeId)"
                :tab-index="getOrderIndex(workerFieldNameEnums.workerTypeId)"
              />
            </KValidationProvider>
          </k-form-group>
          <k-extend-fields
            v-if="form.workerTypeExtFields.extFields"
            v-model="form.workerTypeExtFields"
            ref="workerTypeExtFields"
            v-frag
          />

          <div
            class="cardHead"
            v-if="isFieldShow(workerFieldNameEnums.residentialAddress)"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.residentialAddress),
            }"
          >
            {{ $g("residentialAddress") }}
            <div
              v-if="needAddressDescription"
              id="residentialAddressDiv"
              class="addressDescription"
            >
              {{ $g("residentialAddressDescription") }}
            </div>
          </div>
          <address-form
            id="residentialAddress"
            identifier="residential"
            address-label=""
            :address="residentialAddressForm"
            :is-plain-text="
              nonEditableFields['ResidentialStreetName'] ||
              isFieldViewOnly(workerFieldNameEnums.residentialAddress)
            "
            :street-types="streetTypes"
            :state-types="stateTypes"
            :street-posts="streetPosts"
            :loading="loading"
            :addressformat="addressformat"
            :validate="true"
            :is-show-overseas-address="false"
            overseas-validate
            class="cardBody addressHandle"
            @addressNativeChanged="addressNativeChanged"
            street-name-placeholder="application.streetNamePlaceholder"
            :non-editable-fields="nonEditableFields"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.residentialAddress),
            }"
            v-if="isFieldShow(workerFieldNameEnums.residentialAddress)"
          />
          <div
            class="cardHead"
            v-if="isFieldShow(workerFieldNameEnums.postalAddress)"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.postalAddress),
            }"
          >
            {{ $g("postalAddress") }}
            <div
              v-if="needAddressDescription"
              id="postalAddressDiv"
              class="addressDescription"
            >
              {{ $g("postalAddressDescription1") }}
              {{ $g("postalAddressDescription2") }}
            </div>
          </div>
          <address-form-overseas
            id="postalAddress"
            ref="addressFormOverseas"
            identifier="postal"
            address-label=""
            :address="postalAddressForm"
            :is-plain-text="
              nonEditableFields['MailingAddressLine1'] ||
              isFieldViewOnly(workerFieldNameEnums.postalAddress)
            "
            :loading="loading"
            address-copy-label="sara"
            :address-to-copy="residentialAddressForm"
            :overseas-validate="false"
            :mailing-address-format="mailingaddressformat"
            :has-oversea-country="
              getConfigVal(
                curPageConfigAndDefaultPageConfig,
                'addressFormOverseasOverseaCountry',
                'vIf'
              )
            "
            class="cardBody addressHandle pAdressPart"
            :non-editable-fields="nonEditableFields"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.postalAddress),
            }"
            v-if="isFieldShow(workerFieldNameEnums.postalAddress)"
          />
          <div
            class="cardHead"
            v-if="!isHideStudentFields"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.languageIdList),
            }"
          >
            {{ $g("applicationDetails") }}
          </div>

          <k-form-group
            id="languagesSpokenMultiselect"
            label-suffix="languagesSpoken"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.languageIdList),
            }"
            v-if="isFieldShow(workerFieldNameEnums.languageIdList)"
            :label-class="
              isFieldRequired(workerFieldNameEnums.languageIdList)
                ? 'require-mark'
                : ''
            "
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.languageIdList),
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-multiselect
                v-model="form.languageList"
                :options="languageList"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.languageIdList)
                "
                :tab-index="getOrderIndex(workerFieldNameEnums.languageIdList)"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="travelSelect"
            :label-suffix="
              !getConfigVal(
                curPageConfigAndDefaultPageConfig,
                'travelSelect',
                'vIf'
              )
                ? 'travelAssignment'
                : ''
            "
            :label="
              getConfigVal(
                curPageConfigAndDefaultPageConfig,
                'travelSelect',
                'vIf'
              )
                ? 'travelAssignment'
                : ''
            "
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.workTravelId),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.workTravelId)
                ? 'require-mark'
                : ''
            "
            v-if="
              isFieldShow(workerFieldNameEnums.workTravelId) && !isHideTravel
            "
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.workTravelId),
              }"
              v-slot="{ errors }"
              slim
              vid="newPassword"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-select
                v-model="form.travelId"
                :options="travelList"
                :plaintext="isFieldViewOnly(workerFieldNameEnums.workTravelId)"
                :tab-index="getOrderIndex(workerFieldNameEnums.workTravelId)"
              />
            </KValidationProvider>
          </k-form-group>

          <k-form-group
            id="municipalityMultiselect"
            label-suffix="Municipality"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(
                workerFieldNameEnums.municipalityBoundaryIds
              ),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.municipalityBoundaryIds)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.municipalityBoundaryIds)"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(
                  workerFieldNameEnums.municipalityBoundaryIds
                ),
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-multiselect
                v-model="form.municipalityBoundaryIds"
                :options="MunicipalityBoundaryList"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.municipalityBoundaryIds)
                "
                :tab-index="
                  getOrderIndex(workerFieldNameEnums.municipalityBoundaryIds)
                "
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-suffix="pastThreeYears"
            id="pastThreeYearsRadio"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.asWorkerPastThreeYear),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.asWorkerPastThreeYear)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.asWorkerPastThreeYear)"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(
                  workerFieldNameEnums.asWorkerPastThreeYear
                ),
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <b-form-radio-group
                :options="yesOrNoOptions"
                class="mt-lg-2"
                v-model="form.asWorkerPastThreeYear"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.asWorkerPastThreeYear)
                "
                :tab-index="
                  getOrderIndex(workerFieldNameEnums.asWorkerPastThreeYear)
                "
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="positionPreferenceCheckbox"
            label-suffix="positionPreference"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.jobIdList),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.jobIdList)
                ? 'require-mark'
                : ''
            "
            v-if="
              !isHideStudentFields &&
              isFieldShow(workerFieldNameEnums.jobIdList)
            "
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.jobIdList),
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-checkbox-group
                v-model="form.jobIdList"
                :options="positionPreferenceList"
                class="col-form-label d-block"
                :disabled="isFieldViewOnly(workerFieldNameEnums.jobIdList)"
                :tab-index="getOrderIndex(workerFieldNameEnums.jobIdList)"
                stacked
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="hearAboutSelect"
            :label-suffix="
              !getConfigVal(
                curPageConfigAndDefaultPageConfig,
                'hearAboutSelect',
                'vIf'
              )
                ? 'hearAbout'
                : ''
            "
            :label="
              getConfigVal(
                curPageConfigAndDefaultPageConfig,
                'hearAboutSelect',
                'vIf'
              )
                ? 'hearAbout'
                : ''
            "
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.workerHearAboutId),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.workerHearAboutId)
                ? 'require-mark'
                : ''
            "
            v-if="
              isFieldShow(workerFieldNameEnums.workerHearAboutId) &&
              !isHideStudentFields
            "
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(
                  workerFieldNameEnums.workerHearAboutId
                ),
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-select
                v-model="form.workerHearAboutId"
                :options="hearAbouts"
                @change="changeHearAbout"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.workerHearAboutId)
                "
                :tab-index="
                  getOrderIndex(workerFieldNameEnums.workerHearAboutId)
                "
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="otherInput"
            v-show="showHearAboutText"
            :label="
              getConfigVal(
                curPageConfigAndDefaultPageConfig,
                'otherInput',
                'vIf'
              )
                ? 'other'
                : ''
            "
            :label-class="showHearAboutText ? 'require-mark' : ''"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.workerHearAboutId),
            }"
            v-if="isFieldShow(workerFieldNameEnums.workerHearAboutId)"
          >
            <KValidationProvider
              :rules="{
                required: showHearAboutText,
                max: 200,
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="workerHearAboutText"
                v-model="form.workerHearAboutText"
                :aria-label="$g('workerHearAboutText')"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.workerHearAboutId)
                "
                :tab-index="
                  getOrderIndex(workerFieldNameEnums.workerHearAboutId)
                "
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="volunteerRadio"
            label-suffix="volunteer"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.volunteer),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.volunteer)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.volunteer)"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.volunteer),
                requireWithZero: true,
              }"
              v-slot="{ errors }"
              slim
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-radio-group
                v-model="form.volunteer"
                :options="yesNoOptions"
                class="col-form-label d-block"
                :plaintext="isFieldViewOnly(workerFieldNameEnums.volunteer)"
                :disabled="form.volunteerDisable == 2"
                :tab-index="getOrderIndex(workerFieldNameEnums.volunteer)"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="workDateShiftTypesCheckbox"
            label-suffix="workDateShiftTypes"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :style="{
              order: getOrderIndex(
                workerFieldNameEnums.workerAvailableShiftList
              ),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.workerAvailableShiftList)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.workerAvailableShiftList)"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(
                  workerFieldNameEnums.workerAvailableShiftList
                ),
              }"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-checkbox-group
                v-model="form.workerAvailableShiftList"
                :options="multipleWorkDateShiftTypesOptions"
                class="col-form-label d-block"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.workerAvailableShiftList)
                "
                :tab-index="
                  getOrderIndex(workerFieldNameEnums.workerAvailableShiftList)
                "
                stacked
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-suffix="interestedInEarlyVotingFor"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: getOrderIndex(
                workerFieldNameEnums.interestingInEarlyVoting
              ),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.interestingInEarlyVoting)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.interestingInEarlyVoting)"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(
                  workerFieldNameEnums.interestingInEarlyVoting
                ),
              }"
              v-slot="{ errors }"
              slim
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-radio-group
                v-model="form.interestingInEarlyVoting"
                :options="yesNoOptions"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.interestingInEarlyVoting)
                "
                class="col-form-label d-block"
                :tab-index="
                  getOrderIndex(workerFieldNameEnums.interestingInEarlyVoting)
                "
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-suffix="interested"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.interestingIn),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.interestingIn)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.interestingIn)"
          >
            <KValidationProvider
              :rules="{
                requireWithZero: true,
                required: isFieldRequired(workerFieldNameEnums.interestingIn),
              }"
              v-slot="{ errors }"
              slim
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-radio-group
                v-model="form.interestingIn"
                :options="yesNoOptions"
                :plaintext="isFieldViewOnly(workerFieldNameEnums.interestingIn)"
                class="col-form-label d-block"
              />
            </KValidationProvider>
          </k-form-group>
          <k-extend-fields
            v-if="
              getConfigVal(
                curPageConfigAndDefaultPageConfig,
                'extendFields',
                'vIf'
              )
            "
            id="extendFields"
            v-model="form"
            ref="extFields"
            v-frag
          />
          <k-form-group
            label-suffix="closedWorkDays"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.closedDayList),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.closedDayList)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.closedDayList)"
          >
            <b-table bordered :fields="fields" :items="closedWorkDaysList">
              <template #cell(closed)="row">
                <KValidationProvider
                  :rules="{
                    required: isFieldRequired(
                      workerFieldNameEnums.closedDayList
                    ),
                  }"
                  v-slot="{ errors }"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-checkbox
                    v-model="row.item.checked"
                    :plaintext="
                      isFieldViewOnly(workerFieldNameEnums.closedDayList)
                    "
                    :tab-index="
                      getOrderIndex(workerFieldNameEnums.closedDayList)
                    "
                  />
                </KValidationProvider>
              </template>
            </b-table>
          </k-form-group>

          <k-form-group
            label-suffix="additionalNotes"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.additionalNotes),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.additionalNotes)
                ? 'require-mark'
                : ''
            "
            v-if="isFieldShow(workerFieldNameEnums.additionalNotes)"
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(workerFieldNameEnums.additionalNotes),
                max: 2000,
              }"
              v-slot="{ errors }"
              slim
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-textarea
                v-model="form.additionalNotes"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.additionalNotes)
                "
                :tab-index="getOrderIndex(workerFieldNameEnums.additionalNotes)"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            id="workerTypeUpload"
            :label-suffix="workerTypeDocumentLabel"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            :no-locale="true"
            :style="{
              order: getOrderIndex(workerFieldNameEnums.workerTypeUpload),
            }"
            :label-class="
              isFieldRequired(workerFieldNameEnums.workerTypeUpload)
                ? 'require-mark'
                : ''
            "
            v-if="
              needWorkerTypeDocument &&
              isFieldShow(workerFieldNameEnums.workerTypeUpload)
            "
          >
            <KValidationProvider
              :rules="{
                required: isFieldRequired(
                  workerFieldNameEnums.workerTypeUpload
                ),
                acceptFileType: accept,
                maxFileSize: size * 1024,
              }"
              v-slot="{ errors }"
              slim
              name="workerTypeDocument"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <common-upload
                ref="fileinput"
                v-model="documentFiles"
                :accept="accept"
                :size="size"
                :is-multiple="true"
                :on-delete="onDelete"
                :placeholder="placeholder"
                :show-message="false"
                :is-use-internal-validate="false"
                :plaintext="
                  isFieldViewOnly(workerFieldNameEnums.workerTypeUpload)
                "
              />
            </KValidationProvider>
            <template
              v-if="
                isFieldShow(workerFieldNameEnums.workerTypeUploadModel) &&
                isFieldShow(workerFieldNameEnums.workerTypeUploadModel)
              "
            >
              <b-list-group-item
                v-for="(item, index) in form.workerTypeUpload
                  .workerTypeUploadModel[0].filesInfo"
                :key="index"
                class="d-flex justify-content-between align-items-center"
              >
                <k-form-input
                  :plaintext="true"
                  v-model="item.fileName"
                  v-if="
                    isFieldViewOnly(workerFieldNameEnums.workerTypeUploadModel)
                  "
                />
                <k-link
                  v-if="
                    !isFieldViewOnly(workerFieldNameEnums.workerTypeUploadModel)
                  "
                  @click="downloadPath(item.documentId)"
                >
                  {{ item.displayName }}
                </k-link>
                <b-icon
                  icon="trash"
                  @click="onDeletFAQDocument(item)"
                  v-if="
                    !isFieldViewOnly(workerFieldNameEnums.workerTypeUploadModel)
                  "
                >
                  {{ $g("delete") }}
                </b-icon>
              </b-list-group-item>
            </template>
          </k-form-group>
          <k-extend-fields
            v-model="form.workerPreferenceExtFields"
            ref="workerPreferenceExtFields"
            v-frag
          />
          <NotificationPreferences
            v-model="form"
            :edit="BasicinfoNotificationPreferencesEdit"
            :non-editable-fields="nonEditableFields"
            v-if="loadNotification"
            :is-apply="true"
            v-frag
          />
        </div>
        <div>
          <k-form-group
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            class="wapBtnCenter"
          >
            <k-button type="submit" variant="primary" class="w-150 mt-3">
              buttons.submit
            </k-button>
          </k-form-group>
        </div>
      </k-form>
    </KValidationObserver>
    <k-popwindow
      id="popCropper"
      @ok="onPopCropper"
      :title="$g('popwindow.photo')"
      :before-close="onBeforeClose"
      class="content-form"
      :check-selected="false"
    >
      <PhotoTailor
        ref="PhotoTailor"
        :request-api="uploadPhotoRequest"
        :getimgurldata="getImgUrlData"
      />
    </k-popwindow>
    <k-popwindow
      id="modal-voterAuthentication"
      :title="$g('voterAuthentication')"
      :hide-ok="true"
    >
      <VoterAuthentication
        @select-successful="onSelectVoterAuthentication"
        :street-types="streetTypes"
        :state-types="stateTypes"
        :street-posts="streetPosts"
        :form-data="FormKeyToLowerCase"
      />
    </k-popwindow>
  </div>
</template>
<script>
import {
  getWorkerTravelsList,
  getElectionEarlyDayList,
  apply,
  getPortalPageSetting,
  getApplyLanguages,
  getPersonLanguageList,
  checkIsStudent,
  getYearOfBirthOptions,
  getWorkerTravelsListV2,
  getPositionPreferenceListV2,
  getApplyworker,
  getWorkerTypes,
  getWorkerTypeUpload,
  multiUploadFile,
  getVolunteerWithWorkerType,
} from "../../api/home";
import {
  originDataChangeMixin,
  getCurPageConfigbyRoutePath,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
  repResult,
  globalEnv,
  isPrevent,
  KSSN,
  storeage,
} from "fe-infrastractures";
import {
  getAddressFormat,
  getMailingAddressFormat,
  getSuffixList,
  downloadDocumentUrl,
} from "@/api/common";
import {
  getWorkerTypeExFields,
  getStreetType,
  getStateType,
  getStreetPost,
  getPartys,
  checkWorktypeAge,
  GetMunicipalityBoundary,
  getHearAbout,
  getStudentId,
  getUploadConfig,
  getRaceOptions,
  getworkerportalpagesetting,
  getSSNSettings,
  getMultipleWorkDateShiftTypesOptions,
} from "@/api/application";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import noPhotoPath from "@/assets/img/photo-slices.png";
import http from "fe-infrastractures/src/utils/http";
import VoterAuthentication from "./components/searchVoter.vue";
import NotificationPreferences from "./components/notificationPreferences.vue";
import { getTags } from "../../api/tags";
import {
  getFieldDisplayName,
  getFieldIsHidden,
  getFieldIsRequired,
  getFieldIsViewOnly,
  getFieldOrderIndex,
  workerFieldNameEnums,
  workerModuleType,
} from "@/utils/enums/workerTypeField";
import frag from "vue-frag";
export default {
  mixins: [originDataChangeMixin],
  directives: {
    frag,
  },
  data() {
    let curPageConfig = getCurPageConfigbyRoutePath(
      "workerPortal",
      this.$route.path
    );
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      isPrevent,
      curPageConfigAndDefaultPageConfig,
      languagesSpokenMultiselect:
        curPageConfig?.languagesSpokenMultiselect?.vIf ?? true,
      travelIdSelectVIF:
        curPageConfig?.languagesSpokenMultiselect?.vIf ?? false,
      municipalityMultiselectVIF:
        curPageConfig?.municipalityMultiselect?.vIf ?? false,
      volunteerRadioVIF: curPageConfig?.volunteerRadio?.vIf ?? true,
      description: "",
      form: {
        // extFields: [],
        workerTypeExtFields: {
          extFields: [],
        },
        workerPreferenceExtFields: {
          extFields: [],
        },
        electionId: this.$route.query.electionId,
        languageList: [],
        travelId: "",
        jobIdList: [],
        interestingIn: 2,
        volunteer: 0,
        additionalNotes: "",
        closedDayList: [],
        municipalityBoundaryIds: [],
        selectedTagList: [],
        asWorkerPastThreeYear: 1,
        workerProfileSettings: [],
        electionInspector: null,
      },
      isHideTravel: false,
      showElectionInspector: false,
      languageList: [],
      travelList: [],
      positionPreferenceList: [],
      multipleWorkDateShiftTypesOptions: [],
      MunicipalityBoundaryList: [],
      yesAndNoOptions: [
        { text: "", value: null },
        { text: this.$g("const.options.yes"), value: 2 },
        { text: this.$g("const.options.no"), value: 1 },
      ],
      yesNoOptions: [
        { text: this.$g("const.options.yes"), value: 2 },
        { text: this.$g("const.options.no"), value: 1 },
      ],
      fields: [
        { key: "electionDayStr", label: this.$g("date") },
        { key: "weekDay", label: this.$g("day") },
        { key: "closed", label: this.$g("closed") },
      ],
      closedWorkDaysList: [],
      hideStudentFields: curPageConfig?.hideStudentFields ?? false,
      streetTypes: [],
      streetPosts: [],
      stateTypes: [],
      residentialAddressForm: {
        isSame: false,
        isOverseas: false,
        unit: "",
        streetNumber: "",
        streetName: "",
        streetType: "",
        city: "",
        state: "",
        postcode: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        overseaCountry: "",
        country: "",
        streetFraction: "",
        streetPre: "",
        streetPost: "",
        building: "",
        apartment: "",
      },
      postalAddressForm: {
        isSame: false,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        overseaCountry: "",
      },
      suffixOptions: [],
      partys: [],
      raceOptions: [],
      isShowPassword: false,
      needAddressDescription: false,
      loading: false,
      addressformat: "",
      mailingaddressformat: [],
      dobOptions: [],
      workerTypeList: [],
      hearAbouts: [],
      showHearAboutText: false,
      studentId: "",
      pollId: "",
      needWorkerTypeDocument: false,
      workerTypeDocumentLabel: "",
      documentFiles: [],
      accept: "",
      size: 0,
      placeholder: "No file chosen",
      tagList: [],
      politicalPartyRequired: false,
      yesOrNoOptions: [
        { text: this.$g("yes"), value: 2 },
        { text: this.$g("no"), value: 1 },
      ],
      nonEditableFields: {
        ResidentialStreetName: false,
        MailingAddressLine1: false,
        PreferredContactMethod: false,
      },
      nonEditableFieldsKey: {
        FirstName: "FirstName",
        MiddleName: "MiddleName",
        LastName: "LastName",
        Dob: "Dob",
        ResidentialStreetName: "ResidentialStreetName",
        MailingAddressLine1: "MailingAddressLine1",
        PartyId: "PartyId",
        PreferredContactMethod: "PreferredContactMethod",
      },
      ssnDigits: 9,
      loadNotification: false,
      workerFieldNameEnums: workerFieldNameEnums,
      moduleType: workerModuleType.workerPortalApply,
    };
  },
  components: {
    VoterAuthentication,
    NotificationPreferences,
    KSSN,
  },
  computed: {
    isShowPartyId() {
      if (!this.isFieldShow(workerFieldNameEnums.partyId)) {
        return false;
      }
      if (this.isFieldShow(workerFieldNameEnums.electionInspector)) {
        if (this.showElectionInspector) {
          return this.form.electionInspector == 2;
        }
        return true;
      } else {
        return true;
      }
    },
    FormKeyToLowerCase() {
      this.form = {
        ...this.form,
        ...this.mapPostalAddressForm(),
        ...this.mapResidentialAddressForm(),
      };
      let formDataKey = Object.keys(this.form);
      let formData = {};
      formDataKey.forEach((c) => {
        formData[c.toLowerCase()] = this.form[c];
      });
      return formData;
    },
    pollWorkerExFieldRadiobox2() {
      return this.getExtField("pollWorkerExFieldRadiobox2");
    },
    isHideStudentFields: {
      get() {
        return (
          this.studentId?.toLowerCase() ==
            this.form.workerTypeId?.toLowerCase() &&
          this.getConfigVal(
            this.curPageConfigAndDefaultPageConfig,
            "hideStudentFields",
            "hide"
          )
        );
      },
      set() {},
    },
    pollWorkerExFieldRadiobox1() {
      return this.getExtField("pollWorkerExFieldRadiobox1");
    },
    pollWorkerExFieldStr1() {
      return this.getExtField("pollWorkerExFieldStr1");
    },
    BasicinfoNotificationPreferencesEdit() {
      if (this.nonEditableFields["PreferredContactMethod"]) {
        return false;
      }
      return true;
    },
  },
  async created() {
    this.getSSNSettingsFun();
    getPartys().then((res) => {
      this.partys = res.data;
    });
    GetMunicipalityBoundary().then((r) => {
      this.MunicipalityBoundaryList = r.data;
    });
    getYearOfBirthOptions().then((result) => {
      this.dobOptions = result.data;
    });
    getWorkerTypes().then((res) => {
      this.workerTypeList = res.data;
    });

    await getHearAbout().then((r) => {
      this.hearAbouts = r.data;
    });
    getStudentId().then((r) => {
      this.studentId = r.data;
    });
    getMultipleWorkDateShiftTypesOptions().then((resp) => {
      this.multipleWorkDateShiftTypesOptions = resp.data || [];
    });
    this.getDescription();
    this.bindElectionEarlyDayList();
    this.bindLanguageList();
    this.bindTravelList();
    getStateType({}).then((data) => {
      const items = data.data;
      this.stateTypes = items || [];
      getApplyworker().then((r) => {
        this.form = {
          ...this.form,
          ...r.data,
        };
        this.politicalPartyRequired = r.data.partyRequired == 2;
        if (this.form.photoPath == null || this.form.photoPath == "") {
          this.form.photoPath = noPhotoPath;
        }
        if (
          this.getConfigVal(
            this.curPageConfigAndDefaultPageConfig,
            "pollWorkerExFieldStr1Multiselect",
            "vIf"
          )
        ) {
          this.form.pollWorkerExFieldStr1 = [];
        }
        this.loadNotification = true;
        this.setFormCloseModel(this.form);
        this.setFormData(this.form);
        this.changeHearAbout();
        this.getPositionPreferenceList(this.form.workerTypeId);
        (r.data.workerTypeFieldSettings || []).forEach((d) => {
          if (d.fieldName == "electionInspector") {
            this.showElectionInspector = d.isShow;
          }
          if (d.fieldName == "workTravelId") {
            this.isHideTravel = !d.isShow;
          }
        });
      });
    });
    this.checkIsStudent();
    getUploadConfig("citizenshipdoc").then((data) => {
      if (data.data) {
        this.accept = data.data.acceptTypes;
        this.size = data.data.maxSize / 1024;
      }
    });
    this.getWorkerTypeUpload();
  },
  methods: {
    onDobChange(flag) {
      if (
        !this.form.dob ||
        this.form.dob == -2208988800000 ||
        this.form.dob == 0
      ) {
        return;
      }
      if (!flag) {
        var yob = this.dobOptions.find((q) => q.value == this.form.yob)?.value;
        if (yob) {
          return;
        }
      }
      for (let index = 0; index < this.dobOptions.length; index++) {
        var item = this.dobOptions[index];
        if (item.value && this.form.dob <= item.value) {
          if (index == 0 || this.form.dob == item.value) {
            this.form.yob = item.value;
          } else {
            this.form.yob = this.dobOptions[index - 1].value;
          }
          return;
        }
        if (this.dobOptions.length - 1 == index && item.value < this.form.dob) {
          this.form.yob = item.value;
          return;
        }
      }
    },
    onYobChange() {
      var yob = this.dobOptions.find((q) => q.value == this.form.yob)?.value;
      if (!yob) {
        return;
      }
      if (
        this.form.dob &&
        this.form.dob != -2208988800000 &&
        this.form.dob != 0
      ) {
        return;
      }
      this.form.dob = this.form.yob;
    },
    getOrderIndex(fieldName) {
      return getFieldOrderIndex(fieldName, this.form.workerProfileSettings);
    },
    isFieldShow(fieldName) {
      return !getFieldIsHidden(
        fieldName,
        this.form.workerProfileSettings,
        this.moduleType
      );
    },
    getDisplayName(fieldName) {
      return getFieldDisplayName(fieldName, this.form.workerProfileSettings);
    },
    isFieldRequired(fieldName) {
      return getFieldIsRequired(
        fieldName,
        this.form.workerProfileSettings,
        this.moduleType
      );
    },
    isFieldViewOnly(fieldName) {
      return getFieldIsViewOnly(
        fieldName,
        this.form.workerProfileSettings,
        this.moduleType
      );
    },
    getSSNSettingsFun() {
      getSSNSettings().then((resp) => {
        this.ssnDigits = resp.data || 9;
      });
    },
    addTag(newTag) {
      let tag = {
        text: newTag,
        value: newTag,
      };
      this.$refs.multiselect.options.push(tag);
      this.form.selectedTagList.push(tag.value);
    },
    getExtField(fieldName) {
      let result = null;
      if (this.form.extFields) {
        this.form.extFields.forEach((item) => {
          if (item.fieldName == fieldName) {
            result = { ...item };
          }
        });
      }
      if (
        (fieldName == "pollWorkerExFieldRadiobox1" ||
          fieldName == "pollWorkerExFieldRadiobox2") &&
        (this.pollWorkerExFieldRadiobox2SelectVIF ||
          this.pollWorkerExFieldRadiobox1SelectVIF) &&
        result &&
        result.options &&
        result.options[0]?.value
      ) {
        result?.options.unshift({ text: "", value: null });
      }
      return result;
    },
    changeHearAbout() {
      for (let item of this.hearAbouts) {
        if (this.form.workerHearAboutId == item.value) {
          this.showHearAboutText = item.relateContent;
        }
      }
    },
    onChangeElectionInspector(val) {
      if (val != 2) {
        this.form.partyId = null;
      }
    },
    async checkAge() {
      let result = {
        info: null,
        status: 1,
        data: {
          status: 1,
        },
      };
      if (this.form.dob !== 0 && this.form.dob !== -2208988800000) {
        await checkWorktypeAge({
          workerTypeId: this.form.workerTypeId
            ? this.form.workerTypeId
            : "00000000-0000-0000-0000-000000000000",
          dob: this.form.dob,
        }).then(function (rep) {
          if (rep.data) {
            result = {
              code: 200,
              data: { status: 1, info: true },
              desc: "Successfully",
              warning: "",
              exception: null,
            };
          } else {
            result = rep;
          }
        });
      }
      return result;
    },
    setFormData(data) {
      this.$nextTick(() => {
        this.setOrignData(data);
      });
    },
    getConfigVal,
    getDescription() {
      getPortalPageSetting(pageNameEnum.HomeApply).then((resp) => {
        if (resp.data) {
          this.description = resp.data.description;
        }
      });
    },
    bindLanguageList() {
      getPersonLanguageList().then((resp) => {
        this.languageList = resp.data || [];
      });
      getApplyLanguages().then((resp) => {
        resp.data.forEach((item) => {
          this.form.languageList.push(item.value);
        });
      });
    },
    bindTravelList() {
      getWorkerTravelsList().then((resp) => {
        this.travelList = resp.data || [];
      });
    },
    bindElectionEarlyDayList() {
      getElectionEarlyDayList(this.$route.query.electionId).then((resp) => {
        this.closedWorkDaysList = resp.data || [];
        this.closedWorkDaysList.forEach((item) => {
          item.checked = false;
        });
      });
    },
    setFormCloseModel(item) {
      const form = this.form;
      Object.keys(form).forEach(function (v, i) {
        if (v == "dob" && (!form.dob || form.dob <= 0)) {
          form[v] = item["dobLong"] ?? form[v];
        } else if (v == "partyId" && !form[v]) {
          form[v] = item["partyId"];
        } else if (v != "status" && v != "id" && item[v] && !form[v]) {
          form[v] = item[v];
        }
      });
      const residentialAddress = this.mapResidentialAddressForm();
      Object.keys(residentialAddress).forEach(function (v, i) {
        if (item[v] && !residentialAddress[v]) {
          residentialAddress[v] = item[v];
        }
      });
      const postalAddress = this.mapPostalAddressForm();
      for (let v of Object.keys(postalAddress)) {
        if (item[v] && !postalAddress[v]) {
          postalAddress[v] = item[v];
        }
      }
      this.form = {
        ...form,
        ...residentialAddress,
        ...postalAddress,
      };
      this.onDobChange(true);
      this.setResidentialAddressForm(this.form);
    },
    handleplaintext(item) {
      if (item.hasOwnProperty("isOnlyView") && item.isOnlyView) {
        return true;
      } else {
        return this.plaintext;
      }
    },
    async saveForm() {
      if (
        this.isFieldShow(workerFieldNameEnums.dob) &&
        this.isFieldShow(workerFieldNameEnums.yob)
      ) {
        this.onDobChange();
        this.onYobChange();
      }
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(async () => {
          this.form.ssn = this.form.ssn.replaceAll("-", "");
          this.closedWorkDaysList.forEach((item) => {
            if (item.checked) {
              this.form.closedDayList.push(item.electionDayValue);
            }
          });
          this.form.citizenshipFileNames = [];
          this.documentFiles?.forEach((element) => {
            this.form.citizenshipFileNames.push(element.name);
          });
          if (
            this.getConfigVal(
              this.curPageConfigAndDefaultPageConfig,
              "pollWorkerExFieldStr1Multiselect",
              "vIf"
            )
          ) {
            if (
              this.form.pollWorkerExFieldStr1 &&
              Array.isArray(this.form.pollWorkerExFieldStr1)
            ) {
              this.form.pollWorkerExFieldStr1 =
                this.form.pollWorkerExFieldStr1.join(",");
            }
            if (this.form.email) {
              this.form.useEmail = 2;
            }
          }
          let m = await apply(this.form);
          if (m.data.status === repResult.success) {
            await this.uploadDocumentsFile();
            this.$router.push({
              name: "home",
              params: {
                status: m.data.status,
                message: m.message,
              },
            });
          } else {
            this.$alert({
              title: this.$g("const.confirm.confirm"),
              content: m.data.info,
              okText: this.$g("const.confirm.ok"),
            });
          }
        });
      }
    },
    changePhoto() {
      this.$bvModal.show("popCropper");
    },
    uploadPhotoRequest(model) {
      return http.post("/anon/docs", model, {
        loading: true,
        baseURL: globalEnv.VUE_APP_STD_URL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    removePhoto() {
      this.form.photoPath = noPhotoPath;
      this.form.photo = "";
      this.$forceUpdate();
    },
    getImgUrlData(val) {
      this.form.photoPath = val.url;
      this.form.photo = val.id;
      this.$forceUpdate();
    },
    onPopCropper() {
      this.$refs.PhotoTailor.finish();
    },
    onBeforeClose() {
      return this.$bvModal
        .msgBoxConfirm(this.$g("const.confirm.save"), {
          title: this.$g("const.confirm.confirm"),
          cancelTitle: this.$g("const.confirm.saveYes"),
          okTitle: this.$g("const.confirm.no"),
          cancelVariant: "primary",
          okVariant: "secondary",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          return value != null && !value;
        });
    },
    OnVoterAuthenticationClick() {
      this.$bvModal.show("modal-voterAuthentication");
    },
    OnVoterAuthenticationClear() {
      this.canEditDob = true;
      this.form.electorId = "";
      this.form.voterCode = "";
    },
    onSelectVoterAuthentication(item) {
      this.form.electorId = item.electorId;
      this.form.voterCode = item.voterCode;
      this.form.homeSubdivision = item.subDivision;
      this.form.homeSubdivisionId = item.subDivisionId;
      if (this.SubdivisionAndBoundaryModel == 1) {
        this.subdivisionAndBoundaryModelSetBoundaryList(item.boundaryList);
      }
      this.setFormCloseModel(item);
      this.canEditDob = this.form.dob <= 0;
      this.$bvModal.hide("modal-voterAuthentication");
    },

    mapResidentialAddressForm() {
      return {
        isOverseasAddress: this.residentialAddressForm.isOverseas ?? false,
        residentialUnit: this.residentialAddressForm.unit,
        residentialStreet: this.residentialAddressForm.streetNumber,
        residentialStreetNumber: this.residentialAddressForm.streetNumber,
        residentialStreetName: this.residentialAddressForm.streetName,
        residentialStreetType: this.residentialAddressForm.streetType,
        residentialCity: this.residentialAddressForm.city,
        residentialState: this.residentialAddressForm.state,
        residentialPostcode: this.residentialAddressForm.postcode,
        residentialLocality: this.residentialAddressForm.city,
        mailingAddressLine1: this.residentialAddressForm.addressLine1,
        mailingAddressLine2: this.residentialAddressForm.addressLine2,
        mailingAddressLine3: this.residentialAddressForm.addressLine3,
        mailingCountry: this.residentialAddressForm.overseaCountry,
        residentialCountry: this.residentialAddressForm.country,
        residentialStreetFraction: this.residentialAddressForm.streetFraction,
        residentialStreetPre: this.residentialAddressForm.streetPre,
        residentialStreetPost: this.residentialAddressForm.streetPost,
        residentialBuilding: this.residentialAddressForm.building,
        residentialApartment: this.residentialAddressForm.apartment,
      };
    },
    mapPostalAddressForm() {
      return {
        isSameAsResidentialAddress: this.postalAddressForm.isSame ?? false,
        mailingAddressLine1: this.postalAddressForm.addressLine1,
        mailingAddressLine2: this.postalAddressForm.addressLine2,
        mailingAddressLine3: this.postalAddressForm.addressLine3,
        mailingCountry: this.postalAddressForm.overseaCountry,
      };
    },
    addressNativeChanged() {
      this.form = {
        ...this.form,
        ...this.mapResidentialAddressForm(),
        ...this.mapPostalAddressForm(),
      };
      if (this.postalAddressForm.isSame) {
        this.$refs.addressFormOverseas.onIsSameAddressChanged(true);
      }
    },
    setResidentialAddressForm(data) {
      this.residentialAddressForm = {
        isOverseas: false,
        unit: data.residentialUnit,
        streetNumber: data.residentialStreetNumber,
        streetName: data.residentialStreetName,
        streetType: data.residentialStreetType,
        city: data.residentialLocality || data.city,
        state: data.residentialState,
        postcode: data.residentialPostcode,
        addressLine1: data.mailingAddressLine1,
        addressLine2: data.mailingAddressLine2,
        addressLine3: data.mailingAddressLine3,
        overseaCountry: data.mailingCountry,
        country: data.residentialCountry,
        streetFraction: data.residentialStreetFraction,
        streetPre: data.residentialStreetPre,
        streetPost: data.residentialStreetPost,
        building: data.residentialBuilding,
        apartment: data.residentialApartment,
      };
      this.postalAddressForm = {
        isSame: data.isSameAsResidentialAddress == 1 ? true : false,
        addressLine1: data.mailingAddressLine1,
        addressLine2: data.mailingAddressLine2,
        addressLine3: data.mailingAddressLine3,
        overseaCountry: data.mailingCountry || data.mailingCounty,
      };
    },
    workerTypeIdChange(workerTypeId) {
      if (workerTypeId) {
        this.getPositionPreferenceList(workerTypeId);
        getWorkerTypeExFields(workerTypeId, { type: this.moduleType }).then(
          (resp) => {
            this.form.workerTypeExtFields = resp.data || {};
            this.politicalPartyRequired = resp.data.partyRequired == 2;
            this.isHideTravel = false;
            this.showElectionInspector = false;
            this.form.electionInspector = null;
            (resp.data.workerTypeFieldSettings || []).forEach((d) => {
              if (d.fieldName == "electionInspector") {
                this.showElectionInspector = d.isShow;
              }
              if (d.fieldName == "workTravelId") {
                this.isHideTravel = !d.isShow;
              }
            });
          }
        );
        getVolunteerWithWorkerType({
          workerTypeId: workerTypeId,
        }).then((res) => {
          this.form.volunteer = res.data;
          this.form.volunteerDisable = res.data;
        });
      } else {
        this.form.workerTypeExtFields = {
          extFields: [],
        };
        this.form.volunteer = 0;
        this.form.volunteerDisable = 0;
        this.showElectionInspector = false;
        this.form.electionInspector = null;
        this.isHideTravel = false;
        this.positionPreferenceList = [];
      }
    },
    getPositionPreferenceList(workerTypeId) {
      getPositionPreferenceListV2({ workerTypeId: workerTypeId }).then(
        (resp) => {
          this.positionPreferenceList = resp.data || [];
        }
      );
    },
    checkIsStudent() {
      checkIsStudent().then((resp) => {
        this.isHideStudentFields = resp.data && this.hideStudentFields;
      });
    },
    getWorkerTypeUpload() {
      getWorkerTypeUpload().then((res) => {
        this.form.workerTypeUpload = {
          workerTypeUploadModel: [{}],
        };
        this.pollId = res.data.pollId;
        this.needWorkerTypeDocument = res.data.needWorkerTypeDocument;
        if (res.data.workerTypeUploadModel.length) {
          this.workerTypeDocumentLabel =
            res.data.workerTypeUploadModel[0].workerTypeDocumentLabel;
          this.refId2 = res.data.workerTypeUploadModel[0]?.workerTypeUploadId;
          this.form.workerTypeUpload.workerTypeUploadModel[0].filesInfo =
            res.data.workerTypeUploadModel[0].filesInfo;
        }
      });
    },
    onDelete() {},
    uploadDocumentsFile() {
      if (this.documentFiles.length) {
        let formData = new FormData();
        formData.append("RefId", this.pollId);
        formData.append("RefId2", this.refId2);
        formData.append("DocRequestType", 40004);
        formData.append("LogRequestType", 70003);
        this.documentFiles.forEach((element) => {
          formData.append(`Files`, element);
        });
        return multiUploadFile(formData);
      }
    },
    onDeletFAQDocument(val) {
      this.form.workerTypeUpload.workerTypeUploadModel[0].filesInfo.forEach(
        (item, index, arr) => {
          if (item.id === val.id) {
            arr.splice(index, 1);
          }
        }
      );
    },
    downloadPath(documentId) {
      let model = {
        DocumentId: documentId,
      };
      downloadDocumentUrl(model);
    },
  },
  mounted() {
    getworkerportalpagesetting({
      pageName: "MyProfile-BasicInformation",
      languageId: storeage.getLanguageId(),
    }).then((data) => {
      const availableFunctionality = data.data.availableFunctionality || [];
      for (const item of Object.keys(this.nonEditableFieldsKey)) {
        if (availableFunctionality.find((c) => c.key == item && c.activate)) {
          this.nonEditableFields[item] = true;
        } else {
          this.nonEditableFields[item] = false;
        }
      }
      console.log(this.nonEditableFields);
    });
    if (document.getElementsByClassName("multiselect__input").length > 0) {
      document.getElementsByClassName("multiselect__input")[0].ariaLabel =
        document.getElementsByClassName("multiselect__input")[0].placeholder;
      document.getElementsByClassName(
        "multiselect__placeholder"
      )[0].style.color = "#767676";
    }
    getAddressFormat().then((res) => {
      this.addressformat = res.data;
    });
    getMailingAddressFormat().then((res) => {
      this.mailingaddressformat = res.data;
    });
    getStreetType({}).then((data) => {
      const items = data.data;
      this.streetTypes = items || [];
    });
    getStreetPost({}).then((data) => {
      const items = data.data;
      this.streetPosts = items || [];
    });
    getPartys().then((res) => {
      this.partys = res.data;
    });
    getRaceOptions().then((res) => {
      this.raceOptions = res.data;
    });
    getWorkerTravelsListV2().then((resp) => {
      this.travelList = resp.data || [];
    });

    getSuffixList().then((res) => {
      this.suffixOptions = res.data;
    });
    getTags().then((data) => {
      this.tagList = data.data;
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .otherLayoutCard {
    .content-title {
      padding: 20px 0px 12px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: unset;
    }
  }

  .cardHead {
    padding: 0 5px 12px;
  }

  .addressHandle {
    > div.form-row.form-group {
      > label.col-md-3 {
        display: none;
      }

      > div[class*="col-"] {
        flex: 0 0 100%;
        max-width: 100%;

        .space-between {
          min-height: calc(1.5em + 0.75rem + 2px);
        }
      }
    }
  }

  @media (min-width: 768px) {
    .addressHandle {
      > div.form-row.form-group {
        .text-md-left {
          text-align: right !important;
        }

        .col {
          flex: 0 0 58.333333%;
          max-width: 58.333333%;
        }
      }
    }
  }
  @media (min-width: 992px) {
    .addressHandle {
      > div.form-row.form-group {
        .text-md-left {
          text-align: right !important;
        }

        .col {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .voterIdGroup {
      .form-group {
        position: relative;
        min-height: 60px;
      }

      .col-md-7.col-lg-6 {
        position: unset;
      }

      .voterIdBtn {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 100%;
        padding: 0 5px;
      }
    }
    .pAdressPart {
      .form-group {
        > .col-md-7.col-lg-6 {
          > span:first-child {
            > .form-group label {
              width: auto;
            }
          }
        }
      }
    }
  }
  @media (max-width: 350px) {
    .voterIdGroupWithValue {
      .col-md-7.col-lg-6 .row {
        display: block;
      }

      .voterIdBtn {
        position: unset;
        width: 100%;
        display: block !important;
        padding: 0 15px;

        .btn {
          padding: 5px 15px 6px;
        }
      }
    }
  }
}
</style>
