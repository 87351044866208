var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KValidationObserver',{ref:"observer"},[_c('div',{staticClass:"cardHead",style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageId),
    })},[_vm._v(" "+_vm._s(_vm.$g("contactInformation"))+" ")]),(_vm.isFieldShow(_vm.workerFieldNameEnums.languageId))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageId),
    }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.languageId) ? 'require-mark' : '',"id":"languageSelect","label-suffix":"language","label":"language","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.languageId),
      },"slim":"","name":"language"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-multiselect',{attrs:{"multiple":false,"options":_vm.systemLanguageList,"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.languageId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.languageId)},model:{value:(_vm.applicationFromData.languageId),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "languageId", $$v)},expression:"applicationFromData.languageId"}})]}}],null,false,3430000643)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.preferredContactMethod))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.preferredContactMethod),
    }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.preferredContactMethod)
        ? 'require-mark'
        : '',"label-suffix":"preferredContactMethod","label-for":"preferredContactMethod","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(
          _vm.workerFieldNameEnums.preferredContactMethod
        ),
      },"slim":"","name":"preferredContactMethod"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{staticStyle:{"width":"200px"},attrs:{"options":_vm.enumCommunicationSendTypeList,"plaintext":!_vm.edit ||
          _vm.nonEditableFields['PreferredContactMethod'] ||
          _vm.isFieldViewOnly(_vm.workerFieldNameEnums.preferredContactMethod),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.preferredContactMethod)},on:{"change":_vm.preferredContactMethodChange},model:{value:(_vm.applicationFromData.preferredContactMethod),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "preferredContactMethod", $$v)},expression:"applicationFromData.preferredContactMethod"}})]}}],null,false,111697747)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.useEmail))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.useEmail),
    }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.useEmail) ? 'require-mark' : '',"id":"emailRadio","label-suffix":"email","label-for":"email","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.useEmail),
      },"name":"emailPreference"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"mt-lg-2",attrs:{"options":_vm.options,"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useEmail),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useEmail),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.useEmail)},on:{"change":_vm.emailPreferenceChange},model:{value:(_vm.applicationFromData.useEmail),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "useEmail", $$v)},expression:"applicationFromData.useEmail"}})]}}],null,false,288303432)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.email))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.email),
    }),attrs:{"label-suffix":"emailAddress","label-for":"emailAddress","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.email) && _vm.emailPreferenceOn
        ? 'require-mark'
        : ''}},[_c('KValidationProvider',{attrs:{"rules":{
        max: 200,
        email: true,
        required:
          _vm.isFieldRequired(_vm.workerFieldNameEnums.email) && _vm.emailPreferenceOn,
        remote: _vm.checkEmail,
      },"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"emailAddress","plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.email),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.email)},model:{value:(_vm.applicationFromData.email),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "email", $$v)},expression:"applicationFromData.email"}})]}}],null,false,2413780725)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.useSMS))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.useSMS),
    }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.useSMS) ? 'require-mark' : '',"id":"messagesRadio","label-suffix":"messages","label-for":"messages","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.useSMS),
      },"name":"smsPreference"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"mt-lg-2",attrs:{"options":_vm.options,"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useSMS),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.useSMS),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useSMS)},on:{"change":_vm.smsPreferenceChange},model:{value:(_vm.applicationFromData.useSMS),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "useSMS", $$v)},expression:"applicationFromData.useSMS"}})]}}],null,false,967851017)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.sms))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.sms),
    }),attrs:{"id":"phoneNumberInput","label-suffix":_vm.getConfigVal(
        _vm.curPageConfigAndDefaultPageConfig,
        'phoneNumberInput',
        'labelSuffix'
      ),"label-for":"phoneNumber","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.smsPreferenceOn && _vm.isFieldRequired(_vm.workerFieldNameEnums.sms)
        ? 'require-mark'
        : ''}},[_c('KValidationProvider',{attrs:{"rules":{
        phone: true,
        required:
          _vm.smsPreferenceOn && _vm.isFieldRequired(_vm.workerFieldNameEnums.sms),
      },"slim":"","name":"phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-phone-input',{attrs:{"id":"phoneNumber","plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.sms),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.sms)},model:{value:(_vm.applicationFromData.sms),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "sms", $$v)},expression:"applicationFromData.sms"}})]}}],null,false,1812923104)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.usePhone))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.usePhone),
    }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.usePhone) ? 'require-mark' : '',"id":"phoneCallRadio","label-suffix":"phoneCall","label-for":"phoneCall","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.usePhone),
      },"name":"phonePreference"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"mt-lg-2",attrs:{"options":_vm.options,"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.usePhone),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.usePhone),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.usePhone)},on:{"change":_vm.phonePreferenceChange},model:{value:(_vm.applicationFromData.usePhone),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "usePhone", $$v)},expression:"applicationFromData.usePhone"}})]}}],null,false,936002264)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.phone))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.phone),
    }),attrs:{"label-suffix":_vm.getConfigVal(
        _vm.curPageConfigAndDefaultPageConfig,
        'phoneNumber2Input',
        'labelSuffix'
      ),"id":"phoneNumber2Input","label-for":"phoneNumber","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.phonePreferenceOn && _vm.isFieldRequired(_vm.workerFieldNameEnums.phone)
        ? 'require-mark'
        : ''}},[_c('KValidationProvider',{attrs:{"rules":{
        phone: true,
        required:
          _vm.phonePreferenceOn && _vm.isFieldRequired(_vm.workerFieldNameEnums.phone),
      },"slim":"","name":"phoneNumber2"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-phone-input',{attrs:{"id":"phoneNumber2","plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.phone),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.phone)},model:{value:(_vm.applicationFromData.phone),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "phone", $$v)},expression:"applicationFromData.phone"}})]}}],null,false,365874947)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.workPhone))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workPhone),
    }),attrs:{"label-class":_vm.phonePreferenceOn && _vm.isFieldRequired(_vm.workerFieldNameEnums.workPhone)
        ? 'require-mark'
        : '',"label-suffix":"workPhoneNumber","id":"workPhoneInput","label-for":"workPhone","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        phone: true,
        required:
          _vm.phonePreferenceOn &&
          _vm.isFieldRequired(_vm.workerFieldNameEnums.workPhone),
        max: 200,
      },"slim":"","name":"workPhone"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-phone-input',{attrs:{"id":"workPhone","plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.workPhone),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workPhone)},model:{value:(_vm.applicationFromData.workPhone),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "workPhone", $$v)},expression:"applicationFromData.workPhone"}})]}}],null,false,2964902066)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.isUseNoDisturbHours))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.isUseNoDisturbHours),
    }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.isUseNoDisturbHours)
        ? 'require-mark'
        : '',"id":"doNotDisturbHoursRadio","label-suffix":"doNotDisturbHours","label-for":"doNotDisturbHours","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.isUseNoDisturbHours),
      },"name":"notDisturb"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"mt-lg-2",attrs:{"options":_vm.options,"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.isUseNoDisturbHours),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.isUseNoDisturbHours),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.isUseNoDisturbHours)},on:{"change":_vm.notDisturbChange},model:{value:(_vm.applicationFromData.notDisturb),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "notDisturb", $$v)},expression:"applicationFromData.notDisturb"}})]}}],null,false,2720238011)})],1):_vm._e(),(_vm.notDisturbOn && _vm.isFieldShow(_vm.workerFieldNameEnums.noDisturbHours))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.noDisturbHours),
    }),attrs:{"label-suffix":_vm.getConfigVal(
        _vm.curPageConfigAndDefaultPageConfig,
        'notDisturbFromTimepicker',
        'labelSuffix'
      ),"id":"hoursTimepicker","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.notDisturbOn && _vm.isFieldRequired(_vm.workerFieldNameEnums.noDisturbHours)
        ? 'require-mark'
        : ''}},[_c('KValidationProvider',{staticClass:"inline-time",attrs:{"rules":{
        required:
          (_vm.notDisturbOn &&
            _vm.isFieldRequired(_vm.workerFieldNameEnums.noDisturbHours)) ||
          _vm.applicationFromData.noDisturbHoursEnd > 0,
        validateTimeFormat: true,
      },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-timepicker',{staticClass:"datepicker-time",class:{ minWidth: !_vm.edit },attrs:{"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.noDisturbHours),"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.noDisturbHours)},model:{value:(_vm.applicationFromData.noDisturbHoursStart),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "noDisturbHoursStart", $$v)},expression:"applicationFromData.noDisturbHoursStart"}})]}}],null,false,360269823)}),_c('span',{staticClass:"timePart"},[_vm._v("-")]),_c('KValidationProvider',{staticClass:"inline-time",attrs:{"rules":{
        required:
          (_vm.notDisturbOn &&
            _vm.isFieldRequired(_vm.workerFieldNameEnums.noDisturbHours)) ||
          _vm.applicationFromData.noDisturbHoursStart > 0,
        validateTimeFormat: true,
      },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-timepicker',{staticClass:"datepicker-time",class:{ minWidth: !_vm.edit },attrs:{"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.noDisturbHours),"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.noDisturbHours)},model:{value:(_vm.applicationFromData.noDisturbHoursEnd),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "noDisturbHoursEnd", $$v)},expression:"applicationFromData.noDisturbHoursEnd"}})]}}],null,false,1417822992)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.additionalPhoneNumber))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.additionalPhoneNumber),
    }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.additionalPhoneNumber)
        ? 'require-mark'
        : '',"id":"additionalPhoneNumberInput","label-suffix":"additionalPhoneNumber","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.additionalPhoneNumber),
        phone: true,
      },"slim":"","name":"additionalPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-phone-input',{attrs:{"id":"additionalPhoneNumber","aria-label":_vm.$g('additionalPhoneNumber'),"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.additionalPhoneNumber),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.additionalPhoneNumber)},model:{value:(_vm.applicationFromData.additionalPhoneNumber),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "additionalPhoneNumber", $$v)},expression:"applicationFromData.additionalPhoneNumber"}})]}}],null,false,1467732400)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }