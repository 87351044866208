<template>
  <KValidationObserver ref="observer">
    <div
      class="cardHead"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.languageId),
      }"
    >
      {{ $g("contactInformation") }}
    </div>
    <k-form-group
      :label-class="
        isFieldRequired(workerFieldNameEnums.languageId) ? 'require-mark' : ''
      "
      v-if="isFieldShow(workerFieldNameEnums.languageId)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.languageId),
      }"
      id="languageSelect"
      label-suffix="language"
      label="language"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.languageId),
        }"
        v-slot="{ errors }"
        slim
        name="language"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-multiselect
          :multiple="false"
          v-model="applicationFromData.languageId"
          :options="systemLanguageList"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.languageId)"
          :tab-index="getOrderIndex(workerFieldNameEnums.languageId)"
        />
      </KValidationProvider>
    </k-form-group>

    <k-form-group
      :label-class="
        isFieldRequired(workerFieldNameEnums.preferredContactMethod)
          ? 'require-mark'
          : ''
      "
      v-if="isFieldShow(workerFieldNameEnums.preferredContactMethod)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.preferredContactMethod),
      }"
      label-suffix="preferredContactMethod"
      label-for="preferredContactMethod"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(
            workerFieldNameEnums.preferredContactMethod
          ),
        }"
        v-slot="{ errors }"
        slim
        name="preferredContactMethod"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-select
          v-model="applicationFromData.preferredContactMethod"
          :options="enumCommunicationSendTypeList"
          style="width: 200px"
          @change="preferredContactMethodChange"
          :plaintext="
            !edit ||
            nonEditableFields['PreferredContactMethod'] ||
            isFieldViewOnly(workerFieldNameEnums.preferredContactMethod)
          "
          :tab-index="
            getOrderIndex(workerFieldNameEnums.preferredContactMethod)
          "
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :label-class="
        isFieldRequired(workerFieldNameEnums.useEmail) ? 'require-mark' : ''
      "
      v-if="isFieldShow(workerFieldNameEnums.useEmail)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.useEmail),
      }"
      id="emailRadio"
      label-suffix="email"
      label-for="email"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.useEmail),
        }"
        name="emailPreference"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <b-form-radio-group
          v-model="applicationFromData.useEmail"
          class="mt-lg-2"
          @change="emailPreferenceChange"
          :options="options"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.useEmail)"
          :disabled="!edit || isFieldViewOnly(workerFieldNameEnums.useEmail)"
          :tab-index="getOrderIndex(workerFieldNameEnums.useEmail)"
        />
      </KValidationProvider>
    </k-form-group>

    <k-form-group
      v-if="isFieldShow(workerFieldNameEnums.email)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.email),
      }"
      label-suffix="emailAddress"
      label-for="emailAddress"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      :label-class="
        isFieldRequired(workerFieldNameEnums.email) && emailPreferenceOn
          ? 'require-mark'
          : ''
      "
    >
      <KValidationProvider
        :rules="{
          max: 200,
          email: true,
          required:
            isFieldRequired(workerFieldNameEnums.email) && emailPreferenceOn,
          remote: checkEmail,
        }"
        name="email"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          id="emailAddress"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.email)"
          v-model="applicationFromData.email"
          :tab-index="getOrderIndex(workerFieldNameEnums.email)"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      v-if="isFieldShow(workerFieldNameEnums.useSMS)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.useSMS),
      }"
      :label-class="
        isFieldRequired(workerFieldNameEnums.useSMS) ? 'require-mark' : ''
      "
      id="messagesRadio"
      label-suffix="messages"
      label-for="messages"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.useSMS),
        }"
        name="smsPreference"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <b-form-radio-group
          v-model="applicationFromData.useSMS"
          class="mt-lg-2"
          @change="smsPreferenceChange"
          :options="options"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.useSMS)"
          :tab-index="getOrderIndex(workerFieldNameEnums.useSMS)"
          :disabled="!edit || isFieldViewOnly(workerFieldNameEnums.useSMS)"
        />
      </KValidationProvider>
    </k-form-group>

    <k-form-group
      id="phoneNumberInput"
      :label-suffix="
        getConfigVal(
          curPageConfigAndDefaultPageConfig,
          'phoneNumberInput',
          'labelSuffix'
        )
      "
      label-for="phoneNumber"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      v-if="isFieldShow(workerFieldNameEnums.sms)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.sms),
      }"
      :label-class="
        smsPreferenceOn && isFieldRequired(workerFieldNameEnums.sms)
          ? 'require-mark'
          : ''
      "
    >
      <KValidationProvider
        :rules="{
          phone: true,
          required:
            smsPreferenceOn && isFieldRequired(workerFieldNameEnums.sms),
        }"
        slim
        name="phoneNumber"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-phone-input
          id="phoneNumber"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.sms)"
          v-model="applicationFromData.sms"
          :tab-index="getOrderIndex(workerFieldNameEnums.sms)"
        />
      </KValidationProvider>
    </k-form-group>

    <k-form-group
      v-if="isFieldShow(workerFieldNameEnums.usePhone)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.usePhone),
      }"
      :label-class="
        isFieldRequired(workerFieldNameEnums.usePhone) ? 'require-mark' : ''
      "
      id="phoneCallRadio"
      label-suffix="phoneCall"
      label-for="phoneCall"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.usePhone),
        }"
        name="phonePreference"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <b-form-radio-group
          v-model="applicationFromData.usePhone"
          class="mt-lg-2"
          @change="phonePreferenceChange"
          :options="options"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.usePhone)"
          :disabled="!edit || isFieldViewOnly(workerFieldNameEnums.usePhone)"
          :tab-index="getOrderIndex(workerFieldNameEnums.usePhone)"
        />
      </KValidationProvider>
    </k-form-group>

    <k-form-group
      v-if="isFieldShow(workerFieldNameEnums.phone)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.phone),
      }"
      :label-suffix="
        getConfigVal(
          curPageConfigAndDefaultPageConfig,
          'phoneNumber2Input',
          'labelSuffix'
        )
      "
      id="phoneNumber2Input"
      label-for="phoneNumber"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      :label-class="
        phonePreferenceOn && isFieldRequired(workerFieldNameEnums.phone)
          ? 'require-mark'
          : ''
      "
    >
      <KValidationProvider
        :rules="{
          phone: true,
          required:
            phonePreferenceOn && isFieldRequired(workerFieldNameEnums.phone),
        }"
        slim
        name="phoneNumber2"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-phone-input
          id="phoneNumber2"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.phone)"
          v-model="applicationFromData.phone"
          :tab-index="getOrderIndex(workerFieldNameEnums.phone)"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      v-if="isFieldShow(workerFieldNameEnums.workPhone)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.workPhone),
      }"
      :label-class="
        phonePreferenceOn && isFieldRequired(workerFieldNameEnums.workPhone)
          ? 'require-mark'
          : ''
      "
      label-suffix="workPhoneNumber"
      id="workPhoneInput"
      label-for="workPhone"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          phone: true,
          required:
            phonePreferenceOn &&
            isFieldRequired(workerFieldNameEnums.workPhone),
          max: 200,
        }"
        slim
        name="workPhone"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-phone-input
          id="workPhone"
          v-model="applicationFromData.workPhone"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.workPhone)"
          :tab-index="getOrderIndex(workerFieldNameEnums.workPhone)"
        />
      </KValidationProvider>
    </k-form-group>

    <k-form-group
      v-if="isFieldShow(workerFieldNameEnums.isUseNoDisturbHours)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.isUseNoDisturbHours),
      }"
      :label-class="
        isFieldRequired(workerFieldNameEnums.isUseNoDisturbHours)
          ? 'require-mark'
          : ''
      "
      id="doNotDisturbHoursRadio"
      label-suffix="doNotDisturbHours"
      label-for="doNotDisturbHours"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.isUseNoDisturbHours),
        }"
        name="notDisturb"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <b-form-radio-group
          v-model="applicationFromData.notDisturb"
          class="mt-lg-2"
          @change="notDisturbChange"
          :options="options"
          :plaintext="
            !edit || isFieldViewOnly(workerFieldNameEnums.isUseNoDisturbHours)
          "
          :disabled="
            !edit || isFieldViewOnly(workerFieldNameEnums.isUseNoDisturbHours)
          "
          :tab-index="getOrderIndex(workerFieldNameEnums.isUseNoDisturbHours)"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :style="{
        order: getOrderIndex(workerFieldNameEnums.noDisturbHours),
      }"
      :label-suffix="
        getConfigVal(
          curPageConfigAndDefaultPageConfig,
          'notDisturbFromTimepicker',
          'labelSuffix'
        )
      "
      id="hoursTimepicker"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      :label-class="
        notDisturbOn && isFieldRequired(workerFieldNameEnums.noDisturbHours)
          ? 'require-mark'
          : ''
      "
      v-if="notDisturbOn && isFieldShow(workerFieldNameEnums.noDisturbHours)"
    >
      <KValidationProvider
        :rules="{
          required:
            (notDisturbOn &&
              isFieldRequired(workerFieldNameEnums.noDisturbHours)) ||
            applicationFromData.noDisturbHoursEnd > 0,
          validateTimeFormat: true,
        }"
        slim
        v-slot="{ errors }"
        class="inline-time"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-timepicker
          v-model="applicationFromData.noDisturbHoursStart"
          class="datepicker-time"
          :class="{ minWidth: !edit }"
          :tab-index="getOrderIndex(workerFieldNameEnums.noDisturbHours)"
          :plaintext="
            !edit || isFieldViewOnly(workerFieldNameEnums.noDisturbHours)
          "
        />
      </KValidationProvider>
      <span class="timePart">-</span>
      <KValidationProvider
        :rules="{
          required:
            (notDisturbOn &&
              isFieldRequired(workerFieldNameEnums.noDisturbHours)) ||
            applicationFromData.noDisturbHoursStart > 0,
          validateTimeFormat: true,
        }"
        slim
        v-slot="{ errors }"
        class="inline-time"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-timepicker
          v-model="applicationFromData.noDisturbHoursEnd"
          class="datepicker-time"
          :class="{ minWidth: !edit }"
          :tab-index="getOrderIndex(workerFieldNameEnums.noDisturbHours)"
          :plaintext="
            !edit || isFieldViewOnly(workerFieldNameEnums.noDisturbHours)
          "
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      v-if="isFieldShow(workerFieldNameEnums.additionalPhoneNumber)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.additionalPhoneNumber),
      }"
      :label-class="
        isFieldRequired(workerFieldNameEnums.additionalPhoneNumber)
          ? 'require-mark'
          : ''
      "
      id="additionalPhoneNumberInput"
      label-suffix="additionalPhoneNumber"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.additionalPhoneNumber),
          phone: true,
        }"
        slim
        name="additionalPhoneNumber"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-phone-input
          id="additionalPhoneNumber"
          v-model="applicationFromData.additionalPhoneNumber"
          :aria-label="$g('additionalPhoneNumber')"
          :plaintext="
            !edit || isFieldViewOnly(workerFieldNameEnums.additionalPhoneNumber)
          "
          :tab-index="getOrderIndex(workerFieldNameEnums.additionalPhoneNumber)"
        />
      </KValidationProvider>
    </k-form-group>
  </KValidationObserver>
</template>

<script>
import { getPersonLanguageList, checkEmailAddressUsed } from "@/api/home";

import { getPreferredMethod } from "@/api/application";
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import {
  getFieldDisplayName,
  getFieldIsHidden,
  getFieldIsRequired,
  getFieldIsViewOnly,
  getFieldOrderIndex,
  workerFieldNameEnums,
  workerModuleType,
} from "@/utils/enums/workerTypeField";

export default {
  props: {
    edit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    application: {
      type: Object,
      default: function () {
        return {};
      },
    },
    nonEditableFields: {
      type: Object,
      default() {
        return { PreferredContactMethod: false };
      },
    },
    isApply: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  model: { prop: "application", event: "input" },
  components: {},
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      getConfigVal,
      curPageConfigAndDefaultPageConfig,
      applicationFromData: {
        languageId: "",
        preferredContactMethod: 0,
        workerPortalUtilized: 2,
        useEmail: 2,
        email: "",
        useSMS: 2,
        sms: "",
        usePhone: 2,
        phone: "",
        notDisturb: 2,
        noDisturbHoursStart: "",
        noDisturbHoursEnd: "",
        additionalPhoneNumber: "",
        id: "",
        userId: "",
      },
      systemLanguageList: [],
      enumCommunicationSendTypeList: [],
      emailPreferenceOn: false,
      smsPreferenceOn: false,
      phonePreferenceOn: false,
      notDisturbOn: false,
      options: [
        { text: this.$g("on"), value: 2 },
        { text: this.$g("off"), value: 1 },
      ],
      yesNoOptions: [
        { text: this.$g("yes"), value: 2 },
        { text: this.$g("no"), value: 1 },
      ],
      workerUserId: "",
      isApplicationFromDataBindData: false,
      workerFieldNameEnums: workerFieldNameEnums,
      moduleType: this.isApply
        ? workerModuleType.workerPortalApply
        : workerModuleType.workerPortalApplication,
    };
  },
  mounted() {
    this.bindSystemType();
  },
  methods: {
    getOrderIndex(fieldName) {
      return getFieldOrderIndex(
        fieldName,
        this.application.workerProfileSettings
      );
    },
    isFieldShow(fieldName) {
      return !getFieldIsHidden(
        fieldName,
        this.application.workerProfileSettings,
        this.moduleType
      );
    },
    getDisplayName(fieldName) {
      return getFieldDisplayName(
        fieldName,
        this.application.workerProfileSettings
      );
    },
    isFieldRequired(fieldName) {
      return getFieldIsRequired(
        fieldName,
        this.application.workerProfileSettings,
        this.moduleType
      );
    },
    isFieldViewOnly(fieldName) {
      return getFieldIsViewOnly(
        fieldName,
        this.application.workerProfileSettings,
        this.moduleType
      );
    },
    async checkEmail() {
      if (!this.edit) {
        return {
          info: null,
          status: 1,
          data:{
            status:1
          }
        };
      }
      const data = {
        name: this.applicationFromData.email,
        userId: this.applicationFromData.userId,
      };
      let result = {
        info: null,
        status: 1,
      };
      await checkEmailAddressUsed(data).then(function (rep) {
        result = rep;
      });
      return result;
    },
    bindSystemType() {
      this.bindLanguageList();
      this.bindContactMethod();
    },
    bindLanguageList() {
      getPersonLanguageList().then((resp) => {
        let data = resp.data || [];
        data.unshift({ text: "", value: null });
        this.systemLanguageList = data;
        const l = this.systemLanguageList.find(
          (it) => it.value == this.application.languageId
        );
        if (l != null) {
          this.applicationFromData.languageId = this.application.languageId;
        }
        if(
            !this.isFieldShow(workerFieldNameEnums.languageId)
            &&
            (!this.applicationFromData.languageId ||
                this.applicationFromData.languageId.length == 0)
        ){
          this.systemLanguageList.forEach((item) => {
            if (item.isDefault) {
              this.applicationFromData.languageId = item.value;
            }
          });
        }
      });
    },
    bindContactMethod() {
      getPreferredMethod().then((res) => {
        let data = res.data || [];
        data.forEach((item) => {
          if (item.value == 0) {
            item.value = null;
          }
        });
        this.enumCommunicationSendTypeList = data;
      });
    },
    onTimeChange() {
      let me = this;
      setTimeout(function () {
        me.$refs.observer.validate();
      }, 1);
    },
    bindData() {
      if (this.application.preferredContactMethod != 0) {
        this.applicationFromData.preferredContactMethod =
          this.application.preferredContactMethod ?? "";
      }
      this.$set(
        this.applicationFromData,
        "useEmail",
        this.isFieldShow(workerFieldNameEnums.useEmail)
          ? this.application.useEmail
          : 2
      );
      this.$set(this.applicationFromData, "email", this.application.email);
      this.$set(
        this.applicationFromData,
        "useSMS",
        this.isFieldShow(workerFieldNameEnums.useSMS)
          ? this.application.useSMS
          : 2
      );
      this.$set(this.applicationFromData, "sms", this.application.sms);

      this.$set(
        this.applicationFromData,
        "usePhone",
        this.isFieldShow(workerFieldNameEnums.usePhone)
          ? this.application.usePhone
          : 2
      );
      this.$set(this.applicationFromData, "phone", this.application.phone);

      if (this.isFieldShow(workerFieldNameEnums.isUseNoDisturbHours)) {
        this.$set(
          this.applicationFromData,
          "notDisturb",
          this.application.notDisturb
        );
        this.notDisturbOn = this.application.notDisturb == 2;
      } else {
        this.$set(this.applicationFromData, "notDisturb", 1);
      }
      this.$set(
        this.applicationFromData,
        "workPhone",
        this.application.workPhone
      );
      this.$set(
        this.applicationFromData,
        "noDisturbHoursStart",
        this.application.noDisturbHoursStart == 0 ||
          this.application.noDisturbHoursStart == -2208988800000
          ? null
          : this.application.noDisturbHoursStart
      );
      this.$set(
        this.applicationFromData,
        "noDisturbHoursEnd",
        this.application.noDisturbHoursEnd == 0 ||
          this.application.noDisturbHoursEnd == -2208988800000
          ? null
          : this.application.noDisturbHoursEnd
      );
      this.$set(
        this.applicationFromData,
        "additionalPhoneNumber",
        this.application.additionalPhoneNumber
      );
      if (
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "ContactMethodChangeEvent",
          "vIf"
        )
      ) {
        if (this.applicationFromData.preferredContactMethod) {
          this.preferredContactMethodChange(
            this.applicationFromData.preferredContactMethod
          );
        } else {
          this.emailPreferenceOn = true;
          this.smsPreferenceOn = true;
          this.phonePreferenceOn = true;
        }
      } else {
        this.emailPreferenceOn = this.applicationFromData.useEmail == 2;
        this.smsPreferenceOn = this.applicationFromData.useSMS == 2;
        this.phonePreferenceOn = this.applicationFromData.usePhone == 2;
      }
    },
    preferredContactMethodChange(val) {
      if (
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "ContactMethodChangeEvent",
          "vIf"
        )
      ) {
        switch (val) {
          case 1:
            this.emailPreferenceOn = true;
            this.smsPreferenceOn = false;
            this.phonePreferenceOn = false;
            break;
          case 2:
            this.emailPreferenceOn = false;
            this.smsPreferenceOn = false;
            this.phonePreferenceOn = true;
            break;
          case 4:
            this.emailPreferenceOn = false;
            this.smsPreferenceOn = true;
            this.phonePreferenceOn = false;
            break;
          default:
            break;
        }
      }
    },
    emailPreferenceChange(val) {
      this.emailPreferenceOn = val == 2;
    },
    smsPreferenceChange(val) {
      this.smsPreferenceOn = val == 2;
    },
    phonePreferenceChange(val) {
      this.phonePreferenceOn = val == 2;
    },
    notDisturbChange(val) {
      this.notDisturbOn = val == 2;
      if (!this.notDisturbOn) {
        this.applicationFromData.noDisturbHoursStart = "";
        this.applicationFromData.noDisturbHoursEnd = "";
      }
    },
  },
  watch: {
    applicationFromData: {
      handler: function (np) {
        const data = {
          languageId:
            np.languageId && np.languageId.length > 0
              ? np.languageId
              : this.application.languageId,
          preferredContactMethod: np.preferredContactMethod,
          workerPortalUtilized: np.workerPortalUtilized,
          useEmail: np.useEmail,
          email: np.email,
          useSMS: np.useSMS,
          sms: np.sms,
          workPhone: np.workPhone,
          usePhone: np.usePhone,
          phone: np.phone,
          notDisturb: np.notDisturb,
          noDisturbHoursStart: np.noDisturbHoursStart,
          noDisturbHoursEnd: np.noDisturbHoursEnd,
          additionalPhoneNumber: np.additionalPhoneNumber,
        };
        this.$emit("input", Object.assign(this.application, data));
      },
      deep: true,
    },
    application: {
      handler: function (Newdata) {
        this.bindData();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
