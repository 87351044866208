var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layoutContent"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.description != ''),expression:"description != ''"}],staticClass:"contentParty",domProps:{"innerHTML":_vm._s(_vm.description)}}),_c('k-alert-msg',{ref:"lblMsg"}),_c('KValidationObserver',{ref:"observer"},[_c('k-form',{staticClass:"contentParty",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.isPrevent.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.saveForm.apply(null, arguments)}}},[_c('div',{staticClass:"cardBody",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('k-form-group',{style:({
            order: 0,
          }),attrs:{"label-suffix":"election","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind"}},[_c('k-form-input',{attrs:{"plaintext":true},model:{value:(_vm.$route.query.electionTitle),callback:function ($$v) {_vm.$set(_vm.$route.query, "electionTitle", $$v)},expression:"$route.query.electionTitle"}})],1),_c('k-form-group',{style:({
            order: 0,
          }),attrs:{"label-suffix":"electionDate","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind"}},[_c('k-form-input',{attrs:{"plaintext":true},model:{value:(_vm.$route.query.electionDay),callback:function ($$v) {_vm.$set(_vm.$route.query, "electionDay", $$v)},expression:"$route.query.electionDay"}})],1),_c('div',{staticClass:"cardHead",style:({
            order: 0,
          })},[_vm._v(" "+_vm._s(_vm.$g("personalData"))+" ")]),(_vm.isFieldShow(_vm.workerFieldNameEnums.photo))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.photo),
          }),attrs:{"id":"photoImg","label-suffix":"photo","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.photo) ? 'require-mark' : ''}},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('k-img',{staticStyle:{"max-height":"100px"},attrs:{"src":_vm.form.photoPath}})],1),_c('div',{staticClass:"d-flex flex-wrap align-items-start mt-2"},[(!_vm.isFieldViewOnly(_vm.workerFieldNameEnums.photo))?_c('k-button',{staticClass:"mb-1 mr-2",on:{"click":_vm.changePhoto}},[_vm._v(" buttons.upload ")]):_vm._e(),(!_vm.isFieldViewOnly(_vm.workerFieldNameEnums.photo))?_c('k-button',{staticClass:"mb-1",attrs:{"variant":"utility"},on:{"click":_vm.removePhoto}},[_vm._v(" buttons.remove ")]):_vm._e()],1)])]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.electorId))?_c('k-form-group',{staticClass:"voterIdGroup",class:{
            voterIdGroupWithValue:
              _vm.form.voterCode != null && _vm.form.voterCode.length > 0,
          },style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.electorId),
          }),attrs:{"id":"voterId","label-suffix":"voterId","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.electorId)
              ? 'require-mark'
              : ''}},[_c('k-row',[_c('k-col',{attrs:{"cols":"5"}},[(_vm.form.voterCode != null && _vm.form.voterCode.length > 0)?_c('div',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.form.voterCode)+" ")]):_vm._e()]),_c('k-col',{staticClass:"d-flex justify-content-end voterIdBtn",attrs:{"cols":"7"}},[_c('k-button',{on:{"click":_vm.OnVoterAuthenticationClick}},[_vm._v(" buttons.voterAuthentication ")]),_c('k-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.voterCode != null && _vm.form.voterCode.length > 0),expression:"form.voterCode != null && form.voterCode.length > 0"}],staticClass:"ml-2",on:{"click":_vm.OnVoterAuthenticationClear}},[_vm._v(" buttons.clear ")])],1)],1)],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.firstName))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.firstName),
          }),attrs:{"id":"firstNameInput","label-suffix":"firstName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.firstName)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"name":"firstName","rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.firstName),
              max: 200,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"firstName","aria-label":_vm.$g('firstName'),"plaintext":_vm.nonEditableFields[_vm.nonEditableFieldsKey.FirstName] ||
                _vm.isFieldViewOnly(_vm.workerFieldNameEnums.firstName),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.firstName)},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,false,3277518693)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.middleName))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.middleName),
          }),attrs:{"id":"middleNameInput","label-suffix":"middleName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.middleName)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"name":"middleName","rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.middleName),
              max: 200,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"middleName","aria-label":_vm.$g('middleName'),"plaintext":_vm.nonEditableFields[_vm.nonEditableFieldsKey.MiddleName] ||
                _vm.isFieldViewOnly(_vm.workerFieldNameEnums.middleName),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.middleName)},model:{value:(_vm.form.middleName),callback:function ($$v) {_vm.$set(_vm.form, "middleName", $$v)},expression:"form.middleName"}})]}}],null,false,2756732869)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.lastName))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.lastName),
          }),attrs:{"id":"lastNameInput","label-suffix":"lastName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.lastName)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"name":"lastName","rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.lastName),
              max: 200,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"lastName","aria-label":_vm.$g('lastName'),"plaintext":_vm.nonEditableFields[_vm.nonEditableFieldsKey.LastName] ||
                _vm.isFieldViewOnly(_vm.workerFieldNameEnums.lastName),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.lastName)},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,false,1777509413)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.suffix))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.suffix),
          }),attrs:{"id":"suffixInput","label-suffix":"Suffix","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.suffix) ? 'require-mark' : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.suffix),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.suffixOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.suffix),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.suffix)},model:{value:(_vm.form.suffix),callback:function ($$v) {_vm.$set(_vm.form, "suffix", $$v)},expression:"form.suffix"}})]}}],null,false,863463943)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.dob))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.dob),
          }),attrs:{"id":"dobDatepicker","label-suffix":"dob","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.dob) ? 'require-mark' : ''}},[_c('KValidationProvider',{attrs:{"name":"dob","rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.dob),
              validateDateFormat: true,
              validateDateIsRequired: _vm.isFieldRequired(
                _vm.workerFieldNameEnums.dob
              ),
              remote: _vm.checkAge,
              dob: true,
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-datepicker',{attrs:{"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.dob),"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.dob)},on:{"change":_vm.onDobChange},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}})]}}],null,false,1044050145)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.yob))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.yob),
          }),attrs:{"id":"yobDatepicker","label-suffix":"yearOfBirth","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.yob) ? 'require-mark' : ''}},[_c('KValidationProvider',{attrs:{"name":"yob","rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.yob),
              validateDateFormat: true,
              validateDateIsRequired: _vm.isFieldRequired(
                _vm.workerFieldNameEnums.yob
              ),
              remote: _vm.checkAge,
              dob: true,
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.dobOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.yob),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.yob)},on:{"change":_vm.onYobChange},model:{value:(_vm.form.yob),callback:function ($$v) {_vm.$set(_vm.form, "yob", $$v)},expression:"form.yob"}})]}}],null,false,4228425252)})],1):_vm._e(),(
            _vm.showElectionInspector &&
            _vm.isFieldShow(_vm.workerFieldNameEnums.electionInspector)
          )?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.electionInspector),
          }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.electionInspector)
              ? 'require-mark'
              : '',"label-suffix":"electionInspector","label-for":"electionInspector","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(
                _vm.workerFieldNameEnums.electionInspector
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.electionInspector),"options":_vm.yesAndNoOptions,"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.electionInspector)},on:{"change":_vm.onChangeElectionInspector},model:{value:(_vm.form.electionInspector),callback:function ($$v) {_vm.$set(_vm.form, "electionInspector", $$v)},expression:"form.electionInspector"}})]}}],null,false,4139215848)})],1):_vm._e(),(_vm.isShowPartyId)?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.partyId),
          }),attrs:{"id":"partySelect","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-suffix":"party","label-class":_vm.politicalPartyRequired || _vm.form.electionInspector == 2
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.politicalPartyRequired || _vm.form.electionInspector == 2,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.partys,"plaintext":_vm.nonEditableFields[_vm.nonEditableFieldsKey.PartyId] ||
                _vm.isFieldViewOnly(_vm.workerFieldNameEnums.partyId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.partyId)},model:{value:(_vm.form.partyId),callback:function ($$v) {_vm.$set(_vm.form, "partyId", $$v)},expression:"form.partyId"}})]}}],null,false,2723209439)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.ssn))?_c('b-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.ssn),
          }),attrs:{"label-suffix":"sSN","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","id":"ssnTooltip","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.ssn) ? 'require-mark' : ''},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'ssnTooltip',
                  'labelClass'
                ),staticStyle:{"margin-right":"6px"}},[_vm._v(_vm._s(_vm.$g("sSN"))+": ")]),(
                _vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'ssnTooltipIcon',
                  'vIf'
                )
              )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                placement: 'righttop',
                title: _vm.$g('SSNDescription'),
                boundary: 'window',
                container: 'ssnTooltip',
              }),expression:"{\n                placement: 'righttop',\n                title: $g('SSNDescription'),\n                boundary: 'window',\n                container: 'ssnTooltip',\n              }"}],attrs:{"icon":"info-circle-fill"}}):_vm._e()]},proxy:true}],null,false,3409819971)},[_c('KValidationProvider',{attrs:{"rules":_vm.ssnDigits == 9
                ? {
                    required:
                      _vm.isFieldRequired(_vm.workerFieldNameEnums.ssn) &&
                      !_vm.isShowPassword
                        ? true
                        : false,
                    ssnLength: 9,
                    ssn: {
                      firstBanNumber: ['000', '666'],
                      firstBanNumberBetween: [900, 999],
                      middleNumberBetween: [0, 100],
                      lastnNumberBetween: [0, 10000],
                    },
                  }
                : {
                    required:
                      _vm.isFieldRequired(_vm.workerFieldNameEnums.ssn) &&
                      !_vm.isShowPassword
                        ? true
                        : false,
                    ssnLength_4: {
                      numberBetween: [0, 10000],
                    },
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('KSSN',{attrs:{"ssn-digits":_vm.ssnDigits,"has-password-change-btn":true,"props-show-password":true,"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.ssn),"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.ssn)},model:{value:(_vm.form.ssn),callback:function ($$v) {_vm.$set(_vm.form, "ssn", $$v)},expression:"form.ssn"}})]}}],null,false,3198787600)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.raceId))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.raceId),
          }),attrs:{"id":"raceSelect","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-suffix":"whatsYourRace","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.raceId) ? 'require-mark' : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.raceId),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.raceOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.raceId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.raceId)},model:{value:(_vm.form.raceId),callback:function ($$v) {_vm.$set(_vm.form, "raceId", $$v)},expression:"form.raceId"}})]}}],null,false,3895319466)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.workerTypeId))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workerTypeId),
          }),attrs:{"label-suffix":"workerType","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workerTypeId)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.workerTypeId),
            },"slim":"","name":"workerType"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.workerTypeList,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerTypeId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workerTypeId)},on:{"change":_vm.workerTypeIdChange},model:{value:(_vm.form.workerTypeId),callback:function ($$v) {_vm.$set(_vm.form, "workerTypeId", $$v)},expression:"form.workerTypeId"}})]}}],null,false,2885699376)})],1):_vm._e(),(_vm.form.workerTypeExtFields.extFields)?_c('k-extend-fields',{directives:[{name:"frag",rawName:"v-frag"}],ref:"workerTypeExtFields",model:{value:(_vm.form.workerTypeExtFields),callback:function ($$v) {_vm.$set(_vm.form, "workerTypeExtFields", $$v)},expression:"form.workerTypeExtFields"}}):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.residentialAddress))?_c('div',{staticClass:"cardHead",style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.residentialAddress),
          })},[_vm._v(" "+_vm._s(_vm.$g("residentialAddress"))+" "),(_vm.needAddressDescription)?_c('div',{staticClass:"addressDescription",attrs:{"id":"residentialAddressDiv"}},[_vm._v(" "+_vm._s(_vm.$g("residentialAddressDescription"))+" ")]):_vm._e()]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.residentialAddress))?_c('address-form',{staticClass:"cardBody addressHandle",style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.residentialAddress),
          }),attrs:{"id":"residentialAddress","identifier":"residential","address-label":"","address":_vm.residentialAddressForm,"is-plain-text":_vm.nonEditableFields['ResidentialStreetName'] ||
            _vm.isFieldViewOnly(_vm.workerFieldNameEnums.residentialAddress),"street-types":_vm.streetTypes,"state-types":_vm.stateTypes,"street-posts":_vm.streetPosts,"loading":_vm.loading,"addressformat":_vm.addressformat,"validate":true,"is-show-overseas-address":false,"overseas-validate":"","street-name-placeholder":"application.streetNamePlaceholder","non-editable-fields":_vm.nonEditableFields},on:{"addressNativeChanged":_vm.addressNativeChanged}}):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.postalAddress))?_c('div',{staticClass:"cardHead",style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.postalAddress),
          })},[_vm._v(" "+_vm._s(_vm.$g("postalAddress"))+" "),(_vm.needAddressDescription)?_c('div',{staticClass:"addressDescription",attrs:{"id":"postalAddressDiv"}},[_vm._v(" "+_vm._s(_vm.$g("postalAddressDescription1"))+" "+_vm._s(_vm.$g("postalAddressDescription2"))+" ")]):_vm._e()]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.postalAddress))?_c('address-form-overseas',{ref:"addressFormOverseas",staticClass:"cardBody addressHandle pAdressPart",style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.postalAddress),
          }),attrs:{"id":"postalAddress","identifier":"postal","address-label":"","address":_vm.postalAddressForm,"is-plain-text":_vm.nonEditableFields['MailingAddressLine1'] ||
            _vm.isFieldViewOnly(_vm.workerFieldNameEnums.postalAddress),"loading":_vm.loading,"address-copy-label":"sara","address-to-copy":_vm.residentialAddressForm,"overseas-validate":false,"mailing-address-format":_vm.mailingaddressformat,"has-oversea-country":_vm.getConfigVal(
              _vm.curPageConfigAndDefaultPageConfig,
              'addressFormOverseasOverseaCountry',
              'vIf'
            ),"non-editable-fields":_vm.nonEditableFields}}):_vm._e(),(!_vm.isHideStudentFields)?_c('div',{staticClass:"cardHead",style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageIdList),
          })},[_vm._v(" "+_vm._s(_vm.$g("applicationDetails"))+" ")]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.languageIdList))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageIdList),
          }),attrs:{"id":"languagesSpokenMultiselect","label-suffix":"languagesSpoken","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.languageIdList)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.languageIdList),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-multiselect',{attrs:{"options":_vm.languageList,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.languageIdList),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.languageIdList)},model:{value:(_vm.form.languageList),callback:function ($$v) {_vm.$set(_vm.form, "languageList", $$v)},expression:"form.languageList"}})]}}],null,false,33499685)})],1):_vm._e(),(
            _vm.isFieldShow(_vm.workerFieldNameEnums.workTravelId) && !_vm.isHideTravel
          )?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workTravelId),
          }),attrs:{"id":"travelSelect","label-suffix":!_vm.getConfigVal(
              _vm.curPageConfigAndDefaultPageConfig,
              'travelSelect',
              'vIf'
            )
              ? 'travelAssignment'
              : '',"label":_vm.getConfigVal(
              _vm.curPageConfigAndDefaultPageConfig,
              'travelSelect',
              'vIf'
            )
              ? 'travelAssignment'
              : '',"label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workTravelId)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.workTravelId),
            },"slim":"","vid":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.travelList,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workTravelId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workTravelId)},model:{value:(_vm.form.travelId),callback:function ($$v) {_vm.$set(_vm.form, "travelId", $$v)},expression:"form.travelId"}})]}}],null,false,1798444888)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.municipalityBoundaryIds))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(
              _vm.workerFieldNameEnums.municipalityBoundaryIds
            ),
          }),attrs:{"id":"municipalityMultiselect","label-suffix":"Municipality","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.municipalityBoundaryIds)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(
                _vm.workerFieldNameEnums.municipalityBoundaryIds
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-multiselect',{attrs:{"options":_vm.MunicipalityBoundaryList,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.municipalityBoundaryIds),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.municipalityBoundaryIds)},model:{value:(_vm.form.municipalityBoundaryIds),callback:function ($$v) {_vm.$set(_vm.form, "municipalityBoundaryIds", $$v)},expression:"form.municipalityBoundaryIds"}})]}}],null,false,1902227929)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.asWorkerPastThreeYear))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.asWorkerPastThreeYear),
          }),attrs:{"label-suffix":"pastThreeYears","id":"pastThreeYearsRadio","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.asWorkerPastThreeYear)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(
                _vm.workerFieldNameEnums.asWorkerPastThreeYear
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"mt-lg-2",attrs:{"options":_vm.yesOrNoOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.asWorkerPastThreeYear),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.asWorkerPastThreeYear)},model:{value:(_vm.form.asWorkerPastThreeYear),callback:function ($$v) {_vm.$set(_vm.form, "asWorkerPastThreeYear", $$v)},expression:"form.asWorkerPastThreeYear"}})]}}],null,false,3122572229)})],1):_vm._e(),(
            !_vm.isHideStudentFields &&
            _vm.isFieldShow(_vm.workerFieldNameEnums.jobIdList)
          )?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.jobIdList),
          }),attrs:{"id":"positionPreferenceCheckbox","label-suffix":"positionPreference","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.jobIdList)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.jobIdList),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-checkbox-group',{staticClass:"col-form-label d-block",attrs:{"options":_vm.positionPreferenceList,"disabled":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.jobIdList),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.jobIdList),"stacked":""},model:{value:(_vm.form.jobIdList),callback:function ($$v) {_vm.$set(_vm.form, "jobIdList", $$v)},expression:"form.jobIdList"}})]}}],null,false,235571016)})],1):_vm._e(),(
            _vm.isFieldShow(_vm.workerFieldNameEnums.workerHearAboutId) &&
            !_vm.isHideStudentFields
          )?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workerHearAboutId),
          }),attrs:{"id":"hearAboutSelect","label-suffix":!_vm.getConfigVal(
              _vm.curPageConfigAndDefaultPageConfig,
              'hearAboutSelect',
              'vIf'
            )
              ? 'hearAbout'
              : '',"label":_vm.getConfigVal(
              _vm.curPageConfigAndDefaultPageConfig,
              'hearAboutSelect',
              'vIf'
            )
              ? 'hearAbout'
              : '',"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workerHearAboutId)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(
                _vm.workerFieldNameEnums.workerHearAboutId
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.hearAbouts,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerHearAboutId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workerHearAboutId)},on:{"change":_vm.changeHearAbout},model:{value:(_vm.form.workerHearAboutId),callback:function ($$v) {_vm.$set(_vm.form, "workerHearAboutId", $$v)},expression:"form.workerHearAboutId"}})]}}],null,false,299703591)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.workerHearAboutId))?_c('k-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHearAboutText),expression:"showHearAboutText"}],style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workerHearAboutId),
          }),attrs:{"id":"otherInput","label":_vm.getConfigVal(
              _vm.curPageConfigAndDefaultPageConfig,
              'otherInput',
              'vIf'
            )
              ? 'other'
              : '',"label-class":_vm.showHearAboutText ? 'require-mark' : '',"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.showHearAboutText,
              max: 200,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"workerHearAboutText","aria-label":_vm.$g('workerHearAboutText'),"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerHearAboutId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workerHearAboutId)},model:{value:(_vm.form.workerHearAboutText),callback:function ($$v) {_vm.$set(_vm.form, "workerHearAboutText", $$v)},expression:"form.workerHearAboutText"}})]}}],null,false,382239880)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.volunteer))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.volunteer),
          }),attrs:{"id":"volunteerRadio","label-suffix":"volunteer","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.volunteer)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.volunteer),
              requireWithZero: true,
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-radio-group',{staticClass:"col-form-label d-block",attrs:{"options":_vm.yesNoOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.volunteer),"disabled":_vm.form.volunteerDisable == 2,"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.volunteer)},model:{value:(_vm.form.volunteer),callback:function ($$v) {_vm.$set(_vm.form, "volunteer", $$v)},expression:"form.volunteer"}})]}}],null,false,2499922922)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.workerAvailableShiftList))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(
              _vm.workerFieldNameEnums.workerAvailableShiftList
            ),
          }),attrs:{"id":"workDateShiftTypesCheckbox","label-suffix":"workDateShiftTypes","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workerAvailableShiftList)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(
                _vm.workerFieldNameEnums.workerAvailableShiftList
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-checkbox-group',{staticClass:"col-form-label d-block",attrs:{"options":_vm.multipleWorkDateShiftTypesOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerAvailableShiftList),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workerAvailableShiftList),"stacked":""},model:{value:(_vm.form.workerAvailableShiftList),callback:function ($$v) {_vm.$set(_vm.form, "workerAvailableShiftList", $$v)},expression:"form.workerAvailableShiftList"}})]}}],null,false,1471708388)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.interestingInEarlyVoting))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(
              _vm.workerFieldNameEnums.interestingInEarlyVoting
            ),
          }),attrs:{"label-suffix":"interestedInEarlyVotingFor","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.interestingInEarlyVoting)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(
                _vm.workerFieldNameEnums.interestingInEarlyVoting
              ),
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-radio-group',{staticClass:"col-form-label d-block",attrs:{"options":_vm.yesNoOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.interestingInEarlyVoting),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.interestingInEarlyVoting)},model:{value:(_vm.form.interestingInEarlyVoting),callback:function ($$v) {_vm.$set(_vm.form, "interestingInEarlyVoting", $$v)},expression:"form.interestingInEarlyVoting"}})]}}],null,false,1151225661)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.interestingIn))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.interestingIn),
          }),attrs:{"label-suffix":"interested","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.interestingIn)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              requireWithZero: true,
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.interestingIn),
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-radio-group',{staticClass:"col-form-label d-block",attrs:{"options":_vm.yesNoOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.interestingIn)},model:{value:(_vm.form.interestingIn),callback:function ($$v) {_vm.$set(_vm.form, "interestingIn", $$v)},expression:"form.interestingIn"}})]}}],null,false,4131190842)})],1):_vm._e(),(
            _vm.getConfigVal(
              _vm.curPageConfigAndDefaultPageConfig,
              'extendFields',
              'vIf'
            )
          )?_c('k-extend-fields',{directives:[{name:"frag",rawName:"v-frag"}],ref:"extFields",attrs:{"id":"extendFields"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.closedDayList))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.closedDayList),
          }),attrs:{"label-suffix":"closedWorkDays","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.closedDayList)
              ? 'require-mark'
              : ''}},[_c('b-table',{attrs:{"bordered":"","fields":_vm.fields,"items":_vm.closedWorkDaysList},scopedSlots:_vm._u([{key:"cell(closed)",fn:function(row){return [_c('KValidationProvider',{attrs:{"rules":{
                  required: _vm.isFieldRequired(
                    _vm.workerFieldNameEnums.closedDayList
                  ),
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-checkbox',{attrs:{"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.closedDayList),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.closedDayList)},model:{value:(row.item.checked),callback:function ($$v) {_vm.$set(row.item, "checked", $$v)},expression:"row.item.checked"}})]}}],null,true)})]}}],null,false,909626739)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.additionalNotes))?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.additionalNotes),
          }),attrs:{"label-suffix":"additionalNotes","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.additionalNotes)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(_vm.workerFieldNameEnums.additionalNotes),
              max: 2000,
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-textarea',{attrs:{"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.additionalNotes),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.additionalNotes)},model:{value:(_vm.form.additionalNotes),callback:function ($$v) {_vm.$set(_vm.form, "additionalNotes", $$v)},expression:"form.additionalNotes"}})]}}],null,false,13376425)})],1):_vm._e(),(
            _vm.needWorkerTypeDocument &&
            _vm.isFieldShow(_vm.workerFieldNameEnums.workerTypeUpload)
          )?_c('k-form-group',{style:({
            order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workerTypeUpload),
          }),attrs:{"id":"workerTypeUpload","label-suffix":_vm.workerTypeDocumentLabel,"label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","no-locale":true,"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workerTypeUpload)
              ? 'require-mark'
              : ''}},[_c('KValidationProvider',{attrs:{"rules":{
              required: _vm.isFieldRequired(
                _vm.workerFieldNameEnums.workerTypeUpload
              ),
              acceptFileType: _vm.accept,
              maxFileSize: _vm.size * 1024,
            },"slim":"","name":"workerTypeDocument"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('common-upload',{ref:"fileinput",attrs:{"accept":_vm.accept,"size":_vm.size,"is-multiple":true,"on-delete":_vm.onDelete,"placeholder":_vm.placeholder,"show-message":false,"is-use-internal-validate":false,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerTypeUpload)},model:{value:(_vm.documentFiles),callback:function ($$v) {_vm.documentFiles=$$v},expression:"documentFiles"}})]}}],null,false,3436712665)}),(
              _vm.isFieldShow(_vm.workerFieldNameEnums.workerTypeUploadModel) &&
              _vm.isFieldShow(_vm.workerFieldNameEnums.workerTypeUploadModel)
            )?_vm._l((_vm.form.workerTypeUpload
                .workerTypeUploadModel[0].filesInfo),function(item,index){return _c('b-list-group-item',{key:index,staticClass:"d-flex justify-content-between align-items-center"},[(
                  _vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerTypeUploadModel)
                )?_c('k-form-input',{attrs:{"plaintext":true},model:{value:(item.fileName),callback:function ($$v) {_vm.$set(item, "fileName", $$v)},expression:"item.fileName"}}):_vm._e(),(
                  !_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerTypeUploadModel)
                )?_c('k-link',{on:{"click":function($event){return _vm.downloadPath(item.documentId)}}},[_vm._v(" "+_vm._s(item.displayName)+" ")]):_vm._e(),(
                  !_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerTypeUploadModel)
                )?_c('b-icon',{attrs:{"icon":"trash"},on:{"click":function($event){return _vm.onDeletFAQDocument(item)}}},[_vm._v(" "+_vm._s(_vm.$g("delete"))+" ")]):_vm._e()],1)}):_vm._e()],2):_vm._e(),_c('k-extend-fields',{directives:[{name:"frag",rawName:"v-frag"}],ref:"workerPreferenceExtFields",model:{value:(_vm.form.workerPreferenceExtFields),callback:function ($$v) {_vm.$set(_vm.form, "workerPreferenceExtFields", $$v)},expression:"form.workerPreferenceExtFields"}}),(_vm.loadNotification)?_c('NotificationPreferences',{directives:[{name:"frag",rawName:"v-frag"}],attrs:{"edit":_vm.BasicinfoNotificationPreferencesEdit,"non-editable-fields":_vm.nonEditableFields,"is-apply":true},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e()],1),_c('div',[_c('k-form-group',{staticClass:"wapBtnCenter",attrs:{"label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind"}},[_c('k-button',{staticClass:"w-150 mt-3",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" buttons.submit ")])],1)],1)])],1),_c('k-popwindow',{staticClass:"content-form",attrs:{"id":"popCropper","title":_vm.$g('popwindow.photo'),"before-close":_vm.onBeforeClose,"check-selected":false},on:{"ok":_vm.onPopCropper}},[_c('PhotoTailor',{ref:"PhotoTailor",attrs:{"request-api":_vm.uploadPhotoRequest,"getimgurldata":_vm.getImgUrlData}})],1),_c('k-popwindow',{attrs:{"id":"modal-voterAuthentication","title":_vm.$g('voterAuthentication'),"hide-ok":true}},[_c('VoterAuthentication',{attrs:{"street-types":_vm.streetTypes,"state-types":_vm.stateTypes,"street-posts":_vm.streetPosts,"form-data":_vm.FormKeyToLowerCase},on:{"select-successful":_vm.onSelectVoterAuthentication}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }